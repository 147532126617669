import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { RestService } from 'app/shared/rest.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';
import { AuthService } from 'app/shared/auth.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { MatDialog } from '@angular/material/dialog';
import { CoreSectionPopupDialogComponent } from './powerShakePopup/powerShakePopup.component';
import * as moment from 'moment';
import * as _ from 'underscore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { SubscriptionTextPopupDialogComponent } from './subscriptionTextBox-dialog/subscriptionTextpopup-popup-dialog.component';
const dynamicScripts = [
  'assets/js/webflow.js'
  ];
  
@Component({
  selector: 'app-ultlifestyle',
  templateUrl: './ultlifestyle.component.html',
  styleUrls: ['./ultlifestyle.component.scss']
})
export class UltlifestyleComponent implements OnInit {
  @ViewChild('slickModal', { static: false }) slickModal: SlickCarouselComponent;
  @ViewChild('slickModal1', { static: false }) slickModal1: SlickCarouselComponent;
  @ViewChild('slickModal2', { static: false }) slickModal2: SlickCarouselComponent;
  public slideConfig = {
    "dots": false,
    "infinite": true,
    "speed": 500,
    "slidesToShow": 1,
    "adaptiveHeight": false,
    "autoplay": false,
    "autoplaySpeed": 5000,
    "dir": 'ltr',
    "arrows": false
  };
  public slideConfig2 = {
    "dots": false,
    "infinite": true,
    "speed": 500,
    "slidesToShow": 3,
    "adaptiveHeight": false,
    "autoplay": true,
    "slidesToScroll": 3,
    "autoplaySpeed": 5000,
    "dir": 'ltr',
    "arrows": false
  };
  public slideConfig1 = {
    "dots": false,
    "infinite": true,
    "speed": 500,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "adaptiveHeight": false,
    "autoplay": true,
    "autoplaySpeed": 5000,
    "dir": 'ltr',
    "arrows": false
  };
  
  ultPacks: any;
  showPack: any;
  slideIndex: any = 0;
  slides: any;
  data: any;
  webAliasData: any;
  dots: any;
  BucketUrl = environment.BucketURl;
  mobileView: any;
  meetDaveVideos = {};
  coreProducts = {};
  SubscribeProducts = [];
  SubscribeProduct = {};
  dialogRef: any;
  customerReview: any;
  customerReviewImage: any;
  customerReviewVideo: any;
  ratingCount = 5;
  rating = 1;
  reviews: any;
  addImage: boolean = false;
  addVideo: boolean = false;
  reviewSection: { [key: string]: boolean } = {};
  reviewList: any;
  anchors: any;
  anchorsReview: any;
  itemJudgmeRes: any;
  item: any;
  ultImage: any;
  ultLongDetail: any;
  ultLongDetail3: any;
  ultLongDetail4: any;
  toggleMonth1: boolean = false;
  toggleMonth2: boolean = false;
  toggleMonth3: boolean = false;
  toggleMonth4: boolean = false;
  ulttoggle: boolean = false;
  ulttoggle2: boolean = false;
  ulttoggle3: boolean = false;
  ulttoggle4: boolean = false;
  ulttoggle5: boolean = false;
  ultproductsInfo: boolean = false;
  ultproductsInfo2: boolean = false;
  ultproductsInfo3: boolean = false;
  ultproductsInfo4: boolean = false;
  ultproductsInfo5: boolean = false;
  ultlabelInfo: boolean = false;
  ultlabelInfo2: boolean = false;
  ultlabelInfo3: boolean = false;
  ultlabelInfo4: boolean = false;
  ultlabelInfo5: boolean = false;
  ultlearnMore: boolean = false;
  ultlearnMore2: boolean = false;
  ultlearnMore3: boolean = false;
  ultlearnMore4: boolean = false;
  ultlearnMore5: boolean = false;
  ultcustomerReviews: boolean = false;
  ultcustomerReviews2: boolean = false;
  ultcustomerReviews3: boolean = false;
  ultcustomerReviews4: boolean = false;
  ultcustomerReviews5: boolean = false;
  ULTLifestyleProducts: any;
  ULTPerformanceProducts: any;
  ULTImmuneProducts: any;
  ULTCOllagenSupportProducts: any;
  ULTBrainProducts: any;
  YourPrice: any;
  RetailPrices: any;
  customerReviewForms: FormGroup;
  ReviewsExternalID: any;
  reviewMediaFile: any = [];
 @ViewChildren('ReviewElement11240,ReviewElement11241,ReviewElement11242,ReviewElement11243,ReviewElement11248') ReviewElement: QueryList<ElementRef>;
 @ViewChildren('ReviewElement11240Mobile,ReviewElement11241Mobile,ReviewElement11242Mobile,ReviewElement11243Mobile,ReviewElement11248Mobile') ReviewElementMobile: QueryList<ElementRef>;
  constructor(
    private _restService: RestService,
    private _http: HttpClient,
      private _toastr: ToastrService,
      private router: Router,
      private _renderer2: Renderer2,
      public _auth: AuthService,
      public _sharedMethodService: SharedMethodService, 
      public _enrollSession: EnrollSessionService,
      private _matDialog: MatDialog,
      @Inject(DOCUMENT) private _document: Document,
      private _formBuilder: FormBuilder,
  ) { 
    this.customerReviewForms = this.createCustomerReviewForm();
  }

  ngOnInit(): void {
    this.getULTProduct(504);
    this.getULTProduct(644);
    this.getULTProduct(510);
    this.getULTProduct(509);
    this.getULTProduct(508);
    this.showPack=1;
    this.reviewCarousel();
    let screenWidth = window.innerWidth
      if (screenWidth < 550) {
        this.mobileView = true;
      }
      else {
        this.mobileView = false;
      }
      
    const script = this._renderer2.createElement('script');
    script.type = `application/ld+json`;
      script.text = `
      {
          "@context": "https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=340136345"
      }
    `;
    script.async = true;
    script.defer = true;
    this._renderer2.appendChild(this._document.body, script);

    this.ultPacks = [{
      'id': 1,
      'image': '../../../assets/images/ultlifestyleLanding/PowerShakeAppleBerryPouch.png',
      'details': 
        {
          'text1': 'Energy Levels',
          'text2': 'Cravings',
          'text3': 'Detoxify Cells',
          'text4': 'Satisfy Hunger',    
          'text5': 'Burn Fat',
        }
    },
    {
      'id': 2,
      'image': '../../../assets/images/ultlifestyleLanding/SuperAmino.png',
      'details': 
        {
          'text1': 'Create Lean Muscle',
          'text2': 'Burn Fat',
          'text3': 'Elasticity (tighten skin)',
          'text4': 'Mental Function',
          'text5': '',
        }
    },
    {
      'id': 3,
      'image': '../../../assets/images/ultlifestyleLanding/Biome_Medic.png',
      'details': 
        {
          'text1': 'Detoxify Glyphosate',
          'text2': 'Digestion',
          'text3': 'Bloating',
          'text4': 'Immunity',
          'text5': 'Healthy C-Reactive Protein Levels',
        }
    },
    {
      'id': 4,
      'image': '../../../assets/images/ultlifestyleLanding/OrganicCherryNew.png',
      'details': 
        {
          'text1': 'Deepen Sleep',
          'text2': 'Combat Free Radical Damage',
          'text3': 'Healthy Skin',
          'text4': 'Healthy Inflammatory Response',
          'text5': '',
        }
    },
    {
      'id': 5,
      'image': '../../../assets/images/ultlifestyleLanding/SuperCleansR_1.png',
      'details': 
        {
          'text1': 'Healthy bowel function',
          'text2': 'Release toxins and build up',
          'text3': 'Elimination of parasites'
        }
    }
  ]
    document.getElementById("ult").style.display = "flex";
    document.getElementById("ult_immune").style.display = "none";  
    document.getElementById("ult_collagen").style.display = "none";    
    document.getElementById("ult_performance").style.display = "none";
    document.getElementById("pane-A").style.display = "flex";
    document.getElementById("pane-B").style.display = "none";  
    document.getElementById("pane-C").style.display = "none";  
   

    window.onclick = function(event) {
      if (event.target == document.getElementById("myModal")) {
        document.getElementById("myModal").style.display = "none";
      }
      if (event.target == document.getElementById("UltimateLifestyleModal")) {
        document.getElementById("UltimateLifestyleModal").style.display = "none";
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    var SSOToken = urlParams.get('SSOToken');
    this.webAliasData = urlParams.get('giftcard') || sessionStorage.getItem('giftCardName');
    if (SSOToken) {
      window.localStorage.setItem('SSOToken', urlParams.get('SSOToken'));
      urlParams.delete('SSOToken');
      window.location.href = window.location.origin + window.location.pathname;
    }
    else {
      SSOToken = window.localStorage.getItem('SSOToken');
    }
    setTimeout(()=>{
    },10000);

    this.meetDaveVideos = [
        { url: 'https://player.vimeo.com/video/884136301?loop=1&muted=1', titles: 'Introducing Purium’s 90-Day Lifestyle Subscription' },
        { url: 'https://player.vimeo.com/video/870473644?loop=1&muted=1', titles: 'Purium’s Ultimate Lifestyle Transformation' }
    ];
    this.coreProducts = [
      {id:1,heading: 'Power Shake', description: 'Nourishing Greens' , imageUrl: '../../../assets/images/ultlifestyleLanding/PowerShakeAppleBerryPouch.png', star: '../../../assets/images/ultlifestyleLanding/green_Star.png', reviews: "“Love this shake! I absolutely love this green shake! It's often <br *ngIf='!_enrollSession.mobileViewMethod()'> the first thing I consume in the morning, and it makes me feel <br *ngIf='!_enrollSession.mobileViewMethod()'> great! It's so delicious and nourishing, I love it with oat milk, <br *ngIf='!_enrollSession.mobileViewMethod()'> makes it extra creamy and tasty.” – Sarah Hughes"},
      {id:3,heading: 'Biome Medic', description: `<span>Supports a Healthy <br> Gut Microbiome<span>` , imageUrl: '../../../assets/images/ultlifestyleLanding/Biome_Medic.png', star: '../../../assets/images/ultlifestyleLanding/yellow_Star.png', reviews: "“Daily Essential. Introduced to Purium over one year ago. <br *ngIf='!_enrollSession.mobileViewMethod()'> Biome Medic is essential to maintain my gut health and <br *ngIf='!_enrollSession.mobileViewMethod()'> continue my transformation results. I love the way I feel, <br *ngIf='!_enrollSession.mobileViewMethod()'> and I make sure to keep my stock full!” – Sherryl L Bellafiore"},
      {id:2,heading: 'Super Amino 23', description: 'Build Lean <br> Muscle' , imageUrl: '../../../assets/images/ultlifestyleLanding/SuperAmino.png', star: '../../../assets/images/ultlifestyleLanding/lightGreen_Star.png', reviews: "“The energy of the aminos is next level. <br *ngIf='!_enrollSession.mobileViewMethod()'> Love the super aminos for energy, takes brain fog <br *ngIf='!_enrollSession.mobileViewMethod()'> away and helps me focus.” – Luciano Errico"},
      {id:4,heading: 'Apothe-cherry', description: 'Anti-Aging Sleep <br> Support' , imageUrl: '../../../assets/images/ultlifestyleLanding/OrganicCherryNew.png', star: '../../../assets/images/ultlifestyleLanding/red_Star.png', reviews: "“Amazing! I LOVE apothe cherry!! Not only is cherry <br *ngIf='!_enrollSession.mobileViewMethod()'> my fav since forever, apothe has helped me sleep <br *ngIf='!_enrollSession.mobileViewMethod()'> better than anything I've tried in the past! I'm <br *ngIf='!_enrollSession.mobileViewMethod()'> seriously hooked!” – Melanie Conrad"},
      {id:5,heading: 'Super CleansR', description: 'Parasite Cleanse' , imageUrl: '../../../assets/images/ultlifestyleLanding/SuperCleansR_1.png', star: '../../../assets/images/ultlifestyleLanding/black_Star.png', reviews: "“Best cleanse on the market <br *ngIf='!_enrollSession.mobileViewMethod()'> I love Super Cleanse R and use it every time I need <br *ngIf='!_enrollSession.mobileViewMethod()'> to reset my body.” – Kathy Alpers"},
    ];
    
  }

  next() {
    this.slickModal.slickNext();
  }
  prev() {
    this.slickModal.slickPrev();
  }
  nextCore() {
    this.slickModal1.slickNext();
  }
  prevCore() {
    this.slickModal1.slickPrev();
  }
  nextSubscribe() {
    this.slickModal2.slickNext();
  }
  prevSubscribe() {
    this.slickModal2.slickPrev();
  }

  getColors(index?){
  var bannersCss = this.SubscribeProducts;
  if(index === 0){
    $('#Subscribeheading0').css('color', bannersCss[0].color);
    $('#border-box-slick0').css('border', '1px solid '+ bannersCss[0].color);
    $('#product_section_get_button0').css('background-color',bannersCss[0].color);
  }
  if(index === 1){
    $('#Subscribeheading1').css('color', bannersCss[1].color);
    $('#border-box-slick1').css('border', '1px solid '+  bannersCss[1].color);
    $('#product_section_get_button1').css('background-color',bannersCss[1].color);
  }
  if(index === 2){
    $('#Subscribeheading2').css('color', bannersCss[2].color);
    $('#border-box-slick2').css('border', '1px solid '+  bannersCss[2].color);
    $('#product_section_get_button2').css('background-color',bannersCss[2].color);
  }
  if(index === 3){
    $('#Subscribeheading3').css('color', bannersCss[3].color);
    $('#border-box-slick3').css('border', '1px solid '+ bannersCss[3].color);
    $('#product_section_get_button3').css('background-color',bannersCss[3].color);
  }
  if(index === 4){
    $('#Subscribeheading4').css('color', bannersCss[4].color);
    $('#border-box-slick4').css('border', '1px solid '+ bannersCss[4].color);
    $('#product_section_get_button4').css('background-color',bannersCss[4].color);
  }
  }
  
  packType(id) {
   this.showPack=id;
   if (id==1) {
  
    //Reset tab style
    [2,3,4,5].forEach((a) => {
      const elem = document.getElementById(a.toString());
      elem.style.background = "none";
      elem.style.color = "#000";
    });
    //
    const elem1 = document.getElementById(id);
     elem1.style.background = "#749472";
     elem1.style.color = "#fff";
     $('.ult-lifestyle-transformation-text').css('color','#749472');
     $('.product_section_get_button1').css('background-color','#749472');
     document.getElementById("ult").style.display = "flex";
     document.getElementById("ult_collagen").style.display = "none";  
     document.getElementById("ult_immune").style.display = "none";    
     document.getElementById("ult_performance").style.display = "none";
     document.getElementById("ult_brain").style.display = "none"; 
     this.GetAllJudgMeProducts('11200');
     this.productImage('11240');
     this.LongDetail('11240');
     this.productSectionInfo(11,'#749472');
     this.productsPrices('11240');
   }
   if (id==2) {
    //Reset tab style
    [1,3,4,5].forEach((a) => {
      const elem = document.getElementById(a.toString());
      elem.style.background = "none";
      elem.style.color = "#000";
    });
    //
  
  
    const elem = document.getElementById(id);
     elem.style.background = "#A33FA3";
     elem.style.color = "#fff";
     this.GetAllJudgMeProducts('11235');
     this.productImage('11243');
     this.LongDetail('11243');
     this.productsPrices('11243');
     this.productSectionInfo(17,'#A33FA3');
     $('.ult-lifestyle-transformation-text').css('color','#A33FA3');
     $('.product_section_get_button1').css('background-color','#A33FA3');
     document.getElementById("ult").style.display = "none";
     document.getElementById("ult_collagen").style.display = "flex";  
     document.getElementById("ult_immune").style.display = "none";    
     document.getElementById("ult_performance").style.display = "none";
     document.getElementById("ult_brain").style.display = "none"; 
   }
   if (id==3) {
    //Reset tab style
    [1,2,4,5].forEach((a) => {
      const elem = document.getElementById(a.toString());
      elem.style.background = "none";
      elem.style.color = "#000";
    });
    //
  
  
    const elem = document.getElementById(id);
     elem.style.background = "#F99F27";
     elem.style.color = "#fff";
     $('.ult-lifestyle-transformation-text').css('color','#F99F27');
     $('.product_section_get_button1').css('background-color','#F99F27');
     document.getElementById("ult").style.display = "none";
     document.getElementById("ult_collagen").style.display = "none";  
     document.getElementById("ult_immune").style.display = "flex";    
     document.getElementById("ult_performance").style.display = "none";
     document.getElementById("ult_brain").style.display = "none";
     this.GetAllJudgMeProducts('11208');  
     this.productImage('11242');
     this.LongDetail('11242');
     this.productsPrices('11242');
     this.productSectionInfo(20,'#F99F27');
   }
   if (id==4) {
    //Reset tab style
    [1,2,3,5].forEach((a) => {
      const elem = document.getElementById(a.toString());
      elem.style.background = "none";
      elem.style.color = "#000";
    });
    //
  
  
    const elem = document.getElementById(id);
     elem.style.background = "#056FB6";
     elem.style.color = "#fff";
     $('.ult-lifestyle-transformation-text').css('color','#056FB6');
     $('.product_section_get_button1').css('background-color','#056FB6');
     document.getElementById("ult").style.display = "none";
     document.getElementById("ult_collagen").style.display = "none";  
     document.getElementById("ult_immune").style.display = "none";    
     document.getElementById("ult_performance").style.display = "flex"; 
     document.getElementById("ult_brain").style.display = "none"; 
     this.GetAllJudgMeProducts('11210');
     this.productImage('11241');
     this.LongDetail('11241');
     this.productsPrices('11241');
     this.productSectionInfo(23,'#056FB6');
   }
   if (id==5) {
    //Reset tab style
    [1,2,3,4].forEach((a) => {
      const elem = document.getElementById(a.toString());
      elem.style.background = "none";
      elem.style.color = "#000";
    });
    //
  
  
    const elem = document.getElementById(id);
     elem.style.background = "#164BD3";
     elem.style.color = "#fff";
     $('.ult-lifestyle-transformation-text').css('color','#164BD3');
     $('.product_section_get_button1').css('background-color','#164BD3');
     
     document.getElementById("ult").style.display = "none";
     document.getElementById("ult_collagen").style.display = "none";  
     document.getElementById("ult_immune").style.display = "none";    
     document.getElementById("ult_performance").style.display = "none";
     document.getElementById("ult_brain").style.display = "flex"; 
     this.GetAllJudgMeProducts('11270');
     this.productSectionInfo(14,'#164BD3');
     this.productImage('11248');
     this.LongDetail('11248');
     this.productsPrices('11248');
   }
    }

    productSectionInfo(id,colorCode?){
      if (id==11) {
  
        //Reset tab style
        [12,13].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
        const elem = document.getElementById(id);
         elem.style.backgroundColor = colorCode ? colorCode : "#749472";
         elem.style.color = "#fff";
         $("#pane-A").css("display", "flex");
         $("#pane-B").css("display", "none"); 
         $("#pane-C").css("display", "none");
       }
       if (id==12) {
        //Reset tab style
        [11,13].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
      
      
        const elem = document.getElementById(id);
         elem.style.backgroundColor = colorCode ? colorCode : "#749472";
         elem.style.color = "#fff";
         $("#pane-A").css("display", "none");
         $("#pane-B").css("display", "flex"); 
         $("#pane-C").css("display", "none");
       }
       if (id==13) {
        //Reset tab style
        [11,12].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
        const elem = document.getElementById(id);
         elem.style.backgroundColor = colorCode ? colorCode : "#749472";
         elem.style.color = "#fff";
         $("#pane-A").css("display", "none");
         $("#pane-B").css("display", "none"); 
         $("#pane-C").css("display", "flex"); 
       }
       if (id==14) {
  
        //Reset tab style
        [15,16].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
        const elem = document.getElementById(id);
         elem.style.backgroundColor = "#164BD3";
         elem.style.color = "#fff";
         $("#pane-D").css("display", "flex");
         $("#pane-E").css("display", "none"); 
         $("#pane-F").css("display", "none");
       }
       if (id==15) {
        //Reset tab style
        [14,16].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
      
      
        const elem = document.getElementById(id);
         elem.style.backgroundColor = "#164BD3";
         elem.style.color = "#fff";
         $("#pane-D").css("display", "none");
         $("#pane-E").css("display", "flex"); 
         $("#pane-F").css("display", "none");
       }
       if (id==16) {
        //Reset tab style
        [14,15].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
        const elem = document.getElementById(id);
         elem.style.backgroundColor = "#164BD3";
         elem.style.color = "#fff";
         $("#pane-D").css("display", "none");
         $("#pane-E").css("display", "none"); 
         $("#pane-F").css("display", "flex"); 
       }
       if (id==17) {
  
        //Reset tab style
        [18,19].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
        const elem = document.getElementById(id);
         elem.style.backgroundColor = "#A33FA3";
         elem.style.color = "#fff";
         $("#pane-G").css("display", "flex");
         $("#pane-H").css("display", "none"); 
         $("#pane-I").css("display", "none");
       }
       if (id==18) {
        //Reset tab style
        [17,19].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
      
      
        const elem = document.getElementById(id);
         elem.style.backgroundColor = "#A33FA3";
         elem.style.color = "#fff";
         $("#pane-G").css("display", "none");
         $("#pane-H").css("display", "flex"); 
         $("#pane-I").css("display", "none");
       }
       if (id==19) {
        //Reset tab style
        [17,18].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
        const elem = document.getElementById(id);
         elem.style.backgroundColor = "#A33FA3";
         elem.style.color = "#fff";
         $("#pane-G").css("display", "none");
         $("#pane-H").css("display", "none"); 
         $("#pane-I").css("display", "flex"); 
       }
       if (id==20) {
  
        //Reset tab style
        [21,22].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
        const elem = document.getElementById(id);
         elem.style.backgroundColor = "#F99F27";
         elem.style.color = "#fff";
         $("#pane-J").css("display", "flex");
         $("#pane-K").css("display", "none"); 
         $("#pane-L").css("display", "none");
       }
       if (id==21) {
        //Reset tab style
        [20,22].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
      
      
        const elem = document.getElementById(id);
         elem.style.backgroundColor = "#F99F27";
         elem.style.color = "#fff";
         $("#pane-J").css("display", "none");
         $("#pane-K").css("display", "flex"); 
         $("#pane-L").css("display", "none");
       }
       if (id==22) {
        //Reset tab style
        [20,21].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
        const elem = document.getElementById(id);
         elem.style.backgroundColor = "#F99F27";
         elem.style.color = "#fff";
         $("#pane-J").css("display", "none");
         $("#pane-K").css("display", "none"); 
         $("#pane-L").css("display", "flex"); 
       }
       if (id==23) {
  
        //Reset tab style
        [24,25].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
        const elem = document.getElementById(id);
         elem.style.backgroundColor = "#056FB6";
         elem.style.color = "#fff";
         $("#pane-M").css("display", "flex");
         $("#pane-N").css("display", "none"); 
         $("#pane-O").css("display", "none");
       }
       if (id==24) {
        //Reset tab style
        [23,25].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
      
      
        const elem = document.getElementById(id);
         elem.style.backgroundColor = "#056FB6";
         elem.style.color = "#fff";
         $("#pane-M").css("display", "none");
         $("#pane-N").css("display", "flex"); 
         $("#pane-O").css("display", "none");
       }
       if (id==25) {
        //Reset tab style
        [23,24].forEach((a) => {
          const elem = document.getElementById(a.toString());
          elem.style.background = "none";
          elem.style.color = "#000";
        });
        //
        const elem = document.getElementById(id);
         elem.style.backgroundColor = "#056FB6";
         elem.style.color = "#fff";
         $("#pane-M").css("display", "none");
         $("#pane-N").css("display", "none"); 
         $("#pane-O").css("display", "flex"); 
       }
    }
    openCoreSection(id){
    this.data='';
    this.ultPacks.filter((item) => {
      if (item.id == id) {
        this.data = item;
        return;
      }
    });
    this.CoreSectionInfoPopup(this.data,id);
    }
    CoreSectionInfoPopup(data:any,id:any){
      this.dialogRef = this._matDialog.open(CoreSectionPopupDialogComponent, {
          width: '80%',
          maxWidth: '80%',
          height: '250px',
          disableClose: true,
          data: [data,id]
      });
      this.dialogRef.afterClosed()
          .subscribe(response => {
    
          });
    }
    SubscriptionTextPopup(){
      this.dialogRef = this._matDialog.open(SubscriptionTextPopupDialogComponent, {
          width: '80%',
          maxWidth: '80%',
          height: '250px',
          disableClose: true,
      });
      this.dialogRef.afterClosed()
          .subscribe(response => {
    
          });
    }
  
    openModel(id) {
    this.data='';
    this.ultPacks.filter((item) => {
      if (item.id == id) {
        this.data = item;
        return;
      }
    })
    document.getElementById("myModal").style.display = "block";
    if(document.getElementById('product')){
      document.getElementById('product').remove();
    };
    var html = '';
    html += '<div id="product-popup" style="display:flex">';
    html += '<div id="product">';
    html +=  '<div style="position: relative;width: 80%">'
    switch(id) {
      case 1:
        html += '<p style="text-align: center;font-size: 30px;color: #3A5838;font-weight: 500;margin:0;">Power Shake</p>';
        html += '<p style=" text-align: center;font-size: 20px;font-weight: 500;font-style: italic;">Nourishing Greens</p>';
        html += '<img src="' + this.data.image + '" loading="lazy" alt="image" class="icons" style="width:50%">';
        break;
      case 2:
        html += '<img src="' + this.data.image + '" loading="lazy" alt="image" class="icons" style="width:50%;position: absolute;z-index: 999;">';
        html += '<img class="superaminoBg" src="../../../assets/images/ultlifestyleLanding/superaminoBgIcon.png" alt="absol-image" loading="lazy" alt="image" style="width: 40%;position: absolute;margin-top: 70px;margin-right: 115px;z-index: 888;">';
        break;
      case 3:
        html += '<img src="' + this.data.image + '" loading="lazy" alt="image" class="icons" style="width:50%;position: absolute;">';
        html += '<img class="biomeBgBottom" src="../../../assets/images/ultlifestyleLanding/biomeBgBottom1.png" alt="absol-image" loading="lazy" alt="image" style="width: 34%;position: absolute;margin-left: 188px;margin-top: 101px;">';
        break;
      case 4:
        html += '<img src="' + this.data.image + '" loading="lazy" alt="image" class="icons" style="width:50%">';
        html += '<img class="TartCherries1" src="../../../assets/images/ultlifestyleLanding/TartCherries.png" alt="absol-image" loading="lazy" alt="image" style="width:50%">';
        break;
      case 5:
        html += '<img src="' + this.data.image + '" loading="lazy" alt="image" class="icons" style="width:50%">';
        html += '';
        break;
      default:
    }
    html += '</div>'
    html += '<div style="padding:50px 50px 15px 50px;border-radius: 30px; text-align: start;" class="cursor-pointer">';
    html += '<h5 class="dialog-section">May Help:</h5>';
    html += '<ul class="dialog-box">';
    html += '<li>' + this.data.details.text1 + '</li>';
    html += '<li>' + this.data.details.text2 + '</li>';
    html += '<li>' + this.data.details.text3 + '</li>';
    this.data.details.text4 && (html += '<li>' + this.data.details.text4 + '</li>');
    if(this.data.details.text5){
      html += '<li>' + this.data.details.text5 + '</li>';
    }  
    html += '</ul>';
    html += '</div>';
    html += '</div>';
    html +=  '<div style="position: relative;width: 80%">';
    switch(id) {
      case 1:
        html += '<img src="../../../assets/images/ultlifestyleLanding/PowerShakePopupImage.png" loading="lazy" alt="image" class="icons" style="width:50%">';
        break;
      case 2:
        html += '<img src="" loading="lazy" alt="image" class="icons" style="width:50%;position: absolute;z-index: 999;">';
        break;
      case 3:
        html += '<img src="" loading="lazy" alt="image" class="icons" style="width:50%;position: absolute;">';
        break;
      case 4:
        html += '<img src="" loading="lazy" alt="image" class="icons" style="width:50%">';
        break;
      case 5:
        html += '<img src="" loading="lazy" alt="image" class="icons" style="width:50%">';
        break;
      default:
    }
    html += '</div>';
    html += '</div>';
    document.getElementById('add_after_me').insertAdjacentHTML("afterend", html);
    }
  
    UltimateLifestyleModal() {
      document.getElementById("UltimateLifestyleModal").style.display = "block";
    }
    
    close()
    {
      document.getElementById('myModal').style.display='none';
    }

    BuyNowToday(itemcode) {
      var queryString = window.localStorage.getItem('SSOToken') ? ("&SSOToken=" + encodeURIComponent(window.localStorage.getItem('SSOToken'))+(this.webAliasData? "&giftcard="+this.webAliasData : '')+"&site=ultlifestyle&itemcode="+ itemcode) : (this.webAliasData? "?site=ultlifestyle&itemcode="+ itemcode+"&giftcard="+this.webAliasData : "?site=ultlifestyle&itemcode="+ itemcode) ;  
      this.router.navigateByUrl('/OrderReview'+queryString);
    }
  
    packSelected(itemcode) {
      this.BuyNowToday(itemcode);
    }
    
    reviewCarousel() {
    let i;
    this.slides = document.getElementsByClassName("review-cards");
    this.dots = document.getElementsByClassName("dot");
    for (i = 0; i < this.slides.length; i++) {
      this.slides[i].style.display = "none";  
    }
    this.slideIndex++;
    if (this.slideIndex > this.slides.length) {this.slideIndex = 1}    
    for (i = 0; i < this.dots.length; i++) {
      this.dots[i].className =  this.dots[i].className.replace(" active-dot", "");
    }
    if(this.slides[this.slideIndex-1] && this.dots[this.slideIndex-1]){
      this.slides[this.slideIndex-1].style.display = "flex";  
      this.dots[this.slideIndex-1].className += " active-dot";
    }
    
    setTimeout(this.reviewCarousel, 30000); 
    }

   loadScript() {
    console.log('preparing to load…')
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text / javascript';
      node.async = false;
      node.charset = 'utf - 8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
  RedirectToUltLifestyle() {
    this.router.navigateByUrl('/Ultlifestyle');
  }

  RedirectToShopNow() {
    const shopNowRedirect = document.querySelector('#shopNowRedirect') as HTMLElement;
    if (shopNowRedirect) {
      const scrollPositionAbove = shopNowRedirect.offsetTop; 
      window.scrollTo({ top: scrollPositionAbove, behavior: 'smooth' });
    }
    return;
  }
  ClickToRedirectStartToday() {
    const startFirst = document.querySelector('#startFirst') as HTMLElement;
    if (startFirst) {
      const scrollPositionAbove = startFirst.offsetTop; 
      window.scrollTo({ top: scrollPositionAbove, behavior: 'smooth' });
    }
    setTimeout(() => {
      const startToday = document.querySelector('#startToday') as HTMLElement;
      if (startToday) {
        const scrollPositionAbove = startToday.offsetTop; 
        window.scrollTo({ top: scrollPositionAbove, behavior: 'smooth' });
      }
    }, 600);
  }
  handleReview(handle, itemcode) {
    const reviewHandler = (element) => {
      const observer = new MutationObserver(() => {
        this.anchors = element.nativeElement.querySelectorAll('.jdgm-paginate__page');
        this.anchors.forEach((anchor: HTMLAnchorElement) => {
          anchor.addEventListener('click', ($event: any) => {
            this.getReviews(handle, $event.target.dataset.page, itemcode);
          });
        });
      });
  
      observer.observe(element.nativeElement, { childList: true, subtree: true });
    };
  
    if (this._enrollSession.mobileViewMethod()) {
      this.ReviewElementMobile.forEach((element, index) => {
        reviewHandler(element);
      });
    } else {
      this.ReviewElement.forEach((element, index) => {
        reviewHandler(element);
      });
    }
  }
  GetAllJudgMeProducts(itemcode) {
    this._restService.GetAllJudgMeProducts().subscribe((items: any) => {
      items.forEach((item) => {
        if (item.ItemCode == itemcode) {
          this.ReviewsExternalID = item.ExternalId;
          this.getReviews(item.Handle, 1, itemcode);
          this.handleReview(item.Handle,itemcode);
          this.itemJudgmeRes = item;
        }
        else {
          this.reviewList = '';
          this.reviews = '';
        }
      })
    })
  }
createCustomerReviewForm() {
  return this._formBuilder.group({
      Name: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/)]],
      ReviewTitle: ['', Validators.required],
      WriteYourComment: ['', Validators.required],
      reviewPicture: [''],
      reviewVideo: ['']
  })
}
convertSpecialCharacters(text) {
  return text.replace(/â€™/g, "'")
             .replace(/â€˜/g, "'")
             .replace(/â€œ/g, '"')
             .replace(/â€/g, '"')
             .replace(/â€¦/g, '...')
             .replace(/â€“/g, '-')
             .replace(/â€¢/g, '*')
             .replace(/â€/g, '•')
             .replace(/â€º/g, '›')
             .replace(/â€º/g, '‹')
             .replace(/ðŸ™/g, '🙏')
             .replace(/ðŸ¥°/g, '😰')
             .replace(/ðŸ¦‹/g, '🦋')
             .replace(/ðŸ¿/g, '🏿');
}
getReviews(product, page, itemCode?) {
  let a = product.split(' ');
  let b = a.join('-');
  let externalID = this.ReviewsExternalID;
  this._restService.JudgeMeReviews(b, page).subscribe((res: any) => {
    if(res.product_external_id === externalID){
      let c = res.widget.split("data-number-of-reviews='");
      let d = c[1].split("'");
      this.reviews = d[0];
      let responseHtml = res?.widget;
      responseHtml = responseHtml.replaceAll("data-src", "src")

      if (responseHtml) {
        this.reviewList = this.convertSpecialCharacters(responseHtml);
        var _self = this;
        this.waitForElement('jdgm-write-rev-link', function () { _self.writeCustomerReview(itemCode) });
        this.waitForElement('jdgm-rev__timestamp', function () { _self.addTimestamp() });
      }
    }
  })
}
waitForElement(classname, callback) {
  var poops = setInterval(function () {
      if (document.getElementsByClassName(classname)) {
          clearInterval(poops);
          callback();
      }
  }, 1000);
}
  addTimestamp() {
    if (this._enrollSession.mobileViewMethod()) {
      this.ReviewElementMobile.forEach((element, index) => {
        const momentDate = element.nativeElement.getElementsByClassName('jdgm-rev__timestamp');
        _.each(momentDate, (ele) => {
          let date = ele.dataset.content; let momentObj = moment(date, 'YYYY-MM-DD HH:mm:ss UTC');
          if (momentObj.isValid()) {
            let monthNumber = momentObj.month() + 1;
            let dayNumber = momentObj.date();
            let year = momentObj.year();
            let month = monthNumber < 10 ? '0' + monthNumber : monthNumber;
            let day = dayNumber < 10 ? '0' + dayNumber : dayNumber;
            ele.innerHTML = `${month}/${day}/${year}`;
          }
          else { console.error('Invalid date format:', date); }
        });
      });
    }
    else {
      this.ReviewElement.forEach((element, index) => {
        const momentDate = element.nativeElement.getElementsByClassName('jdgm-rev__timestamp');
        _.each(momentDate, (ele) => {
          let date = ele.dataset.content; let momentObj = moment(date, 'YYYY-MM-DD HH:mm:ss UTC');
          if (momentObj.isValid()) {
            let monthNumber = momentObj.month() + 1;
            let dayNumber = momentObj.date();
            let year = momentObj.year();
            let month = monthNumber < 10 ? '0' + monthNumber : monthNumber;
            let day = dayNumber < 10 ? '0' + dayNumber : dayNumber;
            ele.innerHTML = `${month}/${day}/${year}`;
          }
          else { console.error('Invalid date format:', date); }
        });
      });
    }

  }

  addReviewPicture(event, type) {
    const file: File = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("reviewImage", file);
      this._http.post('https://file.io', formData, { reportProgress: true, observe: 'events' })
        .subscribe(event => {
          let result = JSON.stringify(event);
          let resultBody = JSON.parse(result);
          this.reviewMediaFile.push(resultBody.body.link);
          if (event.type === HttpEventType.UploadProgress) {
          } else if (event instanceof HttpResponse) {
            if (type == 'picture') {
              this.customerReviewImage = file.name;
              this.addImage = true;
            } else if (type == 'video') {
              this.customerReviewVideo = file.name;
              this.addVideo = true;
            }
          }
        });
    }
  }
  pathName(itemCode) {
    let index;
    let path;
    let reviewFormId;
    let mobileViewPath;
    let reviewFormIDMobile;
    let pathArray = {
      index, path, reviewFormId, mobileViewPath, reviewFormIDMobile
    }
    switch (itemCode) {
      case ('11200'):
        pathArray = {
          index: 0,
          path: "ult",
          reviewFormId: 'ult_11200',
          mobileViewPath: 'collapseULT',
          reviewFormIDMobile: 'ult_11200Mobile'
        }
        break;
      case ('11270'):
        pathArray = {
          index: 1,
          path: "ult_brain",
          reviewFormId: 'ult_11248',
          mobileViewPath: 'collapseULTBrain',
          reviewFormIDMobile: 'ult_11248Mobile'
        }
        break;
      case ('11208'):
        pathArray = {
          index: 2,
          path: "ult_immune",
          reviewFormId: 'ult_11208',
          mobileViewPath: 'collapseULTImmune',
          reviewFormIDMobile: 'ult_11208Mobile'
        }
        break;
      case ('11235'):
        pathArray = {
          index: 3,
          path: "ult_collagen",
          reviewFormId: 'ult_11235',
          mobileViewPath: 'collapseULTCollagen',
          reviewFormIDMobile: 'ult_11235Mobile'
        }
        break;
      case ('11210'):
        pathArray = {
          index: 4,
          path: "ult_performance",
          reviewFormId: 'ult_11210',
          mobileViewPath: 'collapseULTPerformance',
          reviewFormIDMobile: 'ult_11210Mobile'
        }
        break;
      default:
    }
    return pathArray;
  }
  writeCustomerReview(itemCode) {
    this.reviewSection[itemCode] = false;
    let pathName = this.pathName(itemCode);
    if(this._enrollSession.mobileViewMethod()){
      this.anchorsReview = document.getElementById(pathName.mobileViewPath);
    }
    else{
      this.anchorsReview = document.getElementById(pathName.path);
    }
    this.anchorsReview?.getElementsByClassName('jdgm-write-rev-link')[0]?.removeAttribute('href');
    this.anchorsReview?.getElementsByClassName('jdgm-write-rev-link')[0]?.setAttribute('display', 'block');
    this.anchorsReview?.getElementsByClassName('jdgm-write-rev-link')[0]?.setAttribute('id', itemCode);
    var _this = this;
    this.reviewMediaFile = [];
    this.addVideo = false;
    this.addImage = false;
    $(_this.anchorsReview).on("click", "#" + itemCode, (event) => {
      _this.reviewSection[itemCode] = true;
      if (sessionStorage.getItem('CustomerReviewMessage')) {
        _this.customerReviewForms.patchValue({
          Name: '',
          Email: '',
          ReviewTitle: '',
          WriteYourComment: '',
          reviewPicture: '',
          reviewVideo: '',
          YoutubeURL: ''
        });
      }
      setTimeout(() => {
        if (_this.reviewSection[itemCode]) {
          let element;
          if(this._enrollSession.mobileViewMethod()){
            element = document.getElementById(pathName.reviewFormIDMobile);
          }
          else{
            element = document.getElementById(pathName.reviewFormId);
          }
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          }
        }
      },1000)
    });
  }


submitReview(itemCode) {
  this.customerReview = this.customerReviewForms.value;
  if (this.customerReviewForms.invalid) {
      this._toastr.error('Please field the required');
      return this.validateAllFormFields(this.customerReviewForms);
  }
  const request = {
      "shop_domain": "puriumreviews.com",
      "platform": "woocommerce",
      "id": this.itemJudgmeRes.ExternalId,
      "email": this.customerReview.Email,
      "name": this.customerReview.Name,
      "reviewer_name_format": "",
      "rating": this.rating,
      "title": this.customerReview.ReviewTitle,
      "body": this.customerReview.WriteYourComment,
      "cf_answers": [{ "cf_question_id": 0, "value": "" }],
      "picture_urls": this.reviewMediaFile,
      "ip_addr": ""
  }
  this._restService.CreateProductReview(request).subscribe({
      next: (result) => {
          this.reviewSection[itemCode] = false;
          sessionStorage.setItem('CustomerReviewMessage', result['message']);
          this.reviewMediaFile = [];
      }, error: (error) => {
          console.log('error', error);
      },
      complete: () => {
      }
  })
}
validateAllFormFields(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
          this.validateAllFormFields(control);
      }
  });
}
productsImage(itemCode?){
  if(itemCode=='11240'){
    return this.ULTLifestyleProducts?.find(item => item.ItemCode === itemCode).SmallPicture;
  }
  if(itemCode=='11241'){
    return this.ULTPerformanceProducts?.find(item => item.ItemCode === itemCode).SmallPicture;
  }
  if(itemCode=='11242'){
    return this.ULTImmuneProducts?.find(item => item.ItemCode === itemCode).SmallPicture;
  }
  if(itemCode=='11243'){
    return this.ULTCOllagenSupportProducts?.find(item => item.ItemCode === itemCode).SmallPicture;
  }
  if(itemCode=='11248'){
    return this.ULTBrainProducts?.find(item => item.ItemCode === itemCode).SmallPicture;
  }
}
productImage(itemCode?){
  if(itemCode == '11240'){
    this.ultImage = this.ULTLifestyleProducts?.find(item => item.ItemCode === itemCode)?.SmallPicture;
  }
  if(itemCode == '11241'){
    this.ultImage = this.ULTPerformanceProducts?.find(item => item.ItemCode === itemCode)?.SmallPicture;
  }
  if(itemCode == '11242'){
    this.ultImage = this.ULTImmuneProducts?.find(item => item.ItemCode === itemCode)?.SmallPicture;
  }
  if(itemCode=='11243'){
    this.ultImage = this.ULTCOllagenSupportProducts?.find(item => item.ItemCode === itemCode)?.SmallPicture;
  }
  if(itemCode=='11248'){
    this.ultImage = this.ULTBrainProducts?.find(item => item.ItemCode === itemCode)?.SmallPicture;
  }
}

productsPrices(itemCode?){
  if(itemCode=='11240'){
    this.YourPrice = this.ULTLifestyleProducts.find(item => item.ItemCode === itemCode).Price;
    this.RetailPrices = this.ULTLifestyleProducts.find(item => item.ItemCode === itemCode).Other1Price;
  }
  if(itemCode=='11241'){
    this.YourPrice = this.ULTPerformanceProducts.find(item => item.ItemCode === itemCode).Price;
    this.RetailPrices = this.ULTPerformanceProducts.find(item => item.ItemCode === itemCode).Other1Price;
  }
  if(itemCode=='11242'){
    this.YourPrice = this.ULTImmuneProducts.find(item => item.ItemCode === itemCode).Price;
    this.RetailPrices = this.ULTImmuneProducts.find(item => item.ItemCode === itemCode).Other1Price;
  }
  if(itemCode=='11243'){
    this.YourPrice = this.ULTCOllagenSupportProducts.find(item => item.ItemCode === itemCode).Price;
    this.RetailPrices = this.ULTCOllagenSupportProducts.find(item => item.ItemCode === itemCode).Other1Price;
  }
  if(itemCode=='11248'){
    this.YourPrice = this.ULTBrainProducts.find(item => item.ItemCode === itemCode).Price;
    this.RetailPrices = this.ULTBrainProducts.find(item => item.ItemCode === itemCode).Other1Price;
  }
}
LongDetail(itemCode?){
  if(itemCode=='11240'){
    this.ultLongDetail = this.ULTLifestyleProducts.find(item => item.ItemCode === itemCode).LongDetail;
    this.ultLongDetail3 = this.ULTLifestyleProducts.find(item => item.ItemCode === itemCode).LongDetail3;
    this.ultLongDetail4 = this.ULTLifestyleProducts.find(item => item.ItemCode === itemCode).LongDetail4;
  }
  if(itemCode=='11241'){
    this.ultLongDetail = this.ULTPerformanceProducts.find(item => item.ItemCode === itemCode).LongDetail;
  this.ultLongDetail3 = this.ULTPerformanceProducts.find(item => item.ItemCode === itemCode).LongDetail3;
  this.ultLongDetail4 = this.ULTPerformanceProducts.find(item => item.ItemCode === itemCode).LongDetail4;
  }
  if(itemCode=='11242'){
    this.ultLongDetail = this.ULTImmuneProducts.find(item => item.ItemCode === itemCode).LongDetail;
  this.ultLongDetail3 = this.ULTImmuneProducts.find(item => item.ItemCode === itemCode).LongDetail3;
  this.ultLongDetail4 = this.ULTImmuneProducts.find(item => item.ItemCode === itemCode).LongDetail4;
  }
  if(itemCode=='11243'){
    this.ultLongDetail = this.ULTCOllagenSupportProducts.find(item => item.ItemCode === itemCode).LongDetail;
    this.ultLongDetail3 = this.ULTCOllagenSupportProducts.find(item => item.ItemCode === itemCode).LongDetail3;
    this.ultLongDetail4 = this.ULTCOllagenSupportProducts.find(item => item.ItemCode === itemCode).LongDetail4;
  }
  if(itemCode=='11248'){
    this.ultLongDetail = this.ULTBrainProducts.find(item => item.ItemCode === itemCode).LongDetail;
  this.ultLongDetail3 = this.ULTBrainProducts.find(item => item.ItemCode === itemCode).LongDetail3;
  this.ultLongDetail4 = this.ULTBrainProducts.find(item => item.ItemCode === itemCode).LongDetail4;
  }
}
ClickToOpenToggleMonth(id){
  if(id==1){
    if(this.toggleMonth1 == false){
      this.toggleMonth1 = true;
      this.toggleMonth2 = false;
      this.toggleMonth3 = false;
      this.toggleMonth4 = false;
    }
    else{
      this.toggleMonth1 = false;
    }
  }
  if(id==2){
    if(this.toggleMonth2 == false){
      this.toggleMonth2 = true;
      this.toggleMonth1 = false;
      this.toggleMonth3 = false;
      this.toggleMonth4 = false;
    }
    else{
      this.toggleMonth2 = false;
    }
  }
  if(id==3){
    if(this.toggleMonth3 == false){
      this.toggleMonth3 = true;
      this.toggleMonth1 = false;
      this.toggleMonth2 = false;
      this.toggleMonth4 = false;
    }
    else{
      this.toggleMonth3 = false;
    }
  }
  if(id==4){
    if(this.toggleMonth4 == false){
      this.toggleMonth4 = true;
      this.toggleMonth1 = false;
      this.toggleMonth3 = false;
      this.toggleMonth2 = false;
    }
    else{
      this.toggleMonth4 = false;
    }
  }
}
  ClickToOpenULT(id) {
    if (id == 1) {
      if (this.ulttoggle == false) {
        this.ulttoggle = true;
        this.ulttoggle2 = false;
        this.ulttoggle3 = false;
        this.ulttoggle4 = false;
        this.ulttoggle5 = false;
        this.ultcustomerReviews = false;
        this.ultcustomerReviews2 = false;
        this.ultcustomerReviews3 = false;
        this.ultcustomerReviews4 = false;
        this.ultcustomerReviews5 = false;
        this.productImage('11240');
        this.LongDetail('11240');
        this.productsPrices('11240');
        this.GetAllJudgMeProducts('11200');
        $('.bg-ult1').css('background-color','#749472');
        $('.headingTextult1').css('color','#ffffff');
        $('.bg-ult2').css('background-color','#E5E3E3');
        $('.headingTextult2').css('color','#1C4BC0');
        $('.bg-ult3').css('background-color','#E5E3E3');
        $('.headingTextult3').css('color','#A33FA3');
        $('.bg-ult4').css('background-color','#E5E3E3');
        $('.headingTextult4').css('color','#BF8A56');
        $('.bg-ult5').css('background-color','#E5E3E3');
        $('.headingTextult5').css('color','#53A3D7');
        setTimeout(() => {
          const element = document.getElementById('collapseULT');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        },1000);
      }
      else {
        this.ulttoggle = false;
        $('.bg-ult1').css('background-color','#E5E3E3');
        $('.headingTextult1').css('color','#749472');
      }
    }
    if(id==2){
      if(this.ulttoggle2 == false){
        this.ulttoggle2 = true;
        this.ulttoggle = false;
        this.ulttoggle3 = false;
        this.ulttoggle4 = false;
        this.ulttoggle5 = false;
        this.ultcustomerReviews = false;
        this.ultcustomerReviews2 = false;
        this.ultcustomerReviews3 = false;
        this.ultcustomerReviews4 = false;
        this.ultcustomerReviews5 = false;
        this.productImage('11248');
        this.LongDetail('11248');
        this.productsPrices('11248');
        this.GetAllJudgMeProducts('11270');
        $('.bg-ult1').css('background-color','#E5E3E3');
        $('.headingTextult1').css('color','#749472');
        $('.bg-ult2').css('background-color','#1C4BC0');
        $('.headingTextult2').css('color','#ffffff');
        $('.bg-ult3').css('background-color','#E5E3E3');
        $('.headingTextult3').css('color','#A33FA3');
        $('.bg-ult4').css('background-color','#E5E3E3');
        $('.headingTextult4').css('color','#BF8A56');
        $('.bg-ult5').css('background-color','#E5E3E3');
        $('.headingTextult5').css('color','#53A3D7');
        setTimeout(() => {
          const element = document.getElementById('collapseULTBrain');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 1000)
      }
      else{
        this.ulttoggle2 = false;
        $('.bg-ult2').css('background-color','#E5E3E3');
        $('.headingTextult2').css('color','#1C4BC0');
      }
    }
    if(id==3){
      if(this.ulttoggle3 == false){
        this.ulttoggle3 = true;
        this.ulttoggle2 = false;
        this.ulttoggle = false;
        this.ulttoggle4 = false;
        this.ulttoggle5 = false;
        this.ultcustomerReviews = false;
        this.ultcustomerReviews2 = false;
        this.ultcustomerReviews3 = false;
        this.ultcustomerReviews4 = false;
        this.ultcustomerReviews5 = false;
        this.productImage('11243');
        this.LongDetail('11243');
        this.productsPrices('11243');
        this.GetAllJudgMeProducts('11235');
        $('.bg-ult1').css('background-color','#E5E3E3');
        $('.headingTextult1').css('color','#749472');
        $('.bg-ult2').css('background-color','#E5E3E3');
        $('.headingTextult2').css('color','#1C4BC0');
        $('.bg-ult3').css('background-color','#A33FA3');
        $('.headingTextult3').css('color','#ffffff');
        $('.bg-ult4').css('background-color','#E5E3E3');
        $('.headingTextult4').css('color','#BF8A56');
        $('.bg-ult5').css('background-color','#E5E3E3');
        $('.headingTextult5').css('color','#53A3D7');
        setTimeout(() => {
          const element = document.getElementById('collapseULTCollagen');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 1000)
      }
      else{
        this.ulttoggle3 = false;
        $('.bg-ult3').css('background-color','#E5E3E3');
        $('.headingTextult3').css('color','#A33FA3');
      }
    }
    if(id==4){
      if(this.ulttoggle4 == false){
        this.ulttoggle4 = true;
        this.ulttoggle2 = false;
        this.ulttoggle3 = false;
        this.ulttoggle = false;
        this.ulttoggle5 = false;
        this.ultcustomerReviews = false;
        this.ultcustomerReviews2 = false;
        this.ultcustomerReviews3 = false;
        this.ultcustomerReviews4 = false;
        this.ultcustomerReviews5 = false;
        this.productImage('11242');
        this.LongDetail('11242');
        this.productsPrices('11242');
        this.GetAllJudgMeProducts('11208');
        $('.bg-ult1').css('background-color','#E5E3E3');
        $('.headingTextult1').css('color','#749472');
        $('.bg-ult2').css('background-color','#E5E3E3');
        $('.headingTextult2').css('color','#1C4BC0');
        $('.bg-ult3').css('background-color','#E5E3E3');
        $('.headingTextult3').css('color','#A33FA3');
        $('.bg-ult4').css('background-color','#BF8A56');
        $('.headingTextult4').css('color','#ffffff');
        $('.bg-ult5').css('background-color','#E5E3E3');
        $('.headingTextult5').css('color','#53A3D7');
        setTimeout(() => {
          const element = document.getElementById('collapseULTImmune');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 1000)
      }
      else{
        this.ulttoggle4 = false;
        $('.bg-ult4').css('background-color','#E5E3E3');
        $('.headingTextult4').css('color','#BF8A56');
      }
    }
    if(id==5){
      if(this.ulttoggle5 == false){
        this.ulttoggle5 = true;
        this.ulttoggle2 = false;
        this.ulttoggle3 = false;
        this.ulttoggle4 = false;
        this.ulttoggle = false;
        this.ultcustomerReviews = false;
        this.ultcustomerReviews2 = false;
        this.ultcustomerReviews3 = false;
        this.ultcustomerReviews4 = false;
        this.ultcustomerReviews5 = false;
        this.productImage('11241');
        this.LongDetail('11241');
        this.productsPrices('11241');
        this.GetAllJudgMeProducts('11210');
        $('.bg-ult1').css('background-color','#E5E3E3');
        $('.headingTextult1').css('color','#749472');
        $('.bg-ult2').css('background-color','#E5E3E3');
        $('.headingTextult2').css('color','#1C4BC0');
        $('.bg-ult3').css('background-color','#E5E3E3');
        $('.headingTextult3').css('color','#A33FA3');
        $('.bg-ult4').css('background-color','#E5E3E3');
        $('.headingTextult4').css('color','#BF8A56');
        $('.bg-ult5').css('background-color','#53A3D7');
        $('.headingTextult5').css('color','#ffffff');
        setTimeout(() => {
          const element = document.getElementById('collapseULTPerformance');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 1000);
      }
      else{
        this.ulttoggle5 = false;
        $('.bg-ult5').css('background-color','#E5E3E3');
        $('.headingTextult5').css('color','#53A3D7');
      }
    }
  }
ClickToOpenULTProductsInfo(id){
  if(id==1){
    if(this.ultproductsInfo == false){
      this.ultproductsInfo = true;
    }
    else{
      this.ultproductsInfo = false;
    }
  }
  if(id==2){
    if(this.ultproductsInfo2 == false){
      this.ultproductsInfo2 = true;
    }
    else{
      this.ultproductsInfo2 = false;
    }
  }
  if(id==3){
    if(this.ultproductsInfo3 == false){
      this.ultproductsInfo3 = true;
    }
    else{
      this.ultproductsInfo3 = false;
    }
  }
  if(id==4){
    if(this.ultproductsInfo4 == false){
      this.ultproductsInfo4 = true;
    }
    else{
      this.ultproductsInfo4 = false;
    }
  }
  if(id==5){
    if(this.ultproductsInfo5 == false){
      this.ultproductsInfo5 = true;
    }
    else{
      this.ultproductsInfo5 = false;
    }
  }
}
ClickToOpenULTLabelInfo(id){
  if(id==1){
    if(this.ultlabelInfo == false){
      this.ultlabelInfo = true;
    }
    else{
      this.ultlabelInfo = false;
    }
  }
  if(id==2){
    if(this.ultlabelInfo2 == false){
      this.ultlabelInfo2 = true;
    }
    else{
      this.ultlabelInfo2 = false;
    }
  }
  if(id==3){
    if(this.ultlabelInfo3 == false){
      this.ultlabelInfo3 = true;
    }
    else{
      this.ultlabelInfo3 = false;
    }
  }
  if(id==4){
    if(this.ultlabelInfo4 == false){
      this.ultlabelInfo4 = true;
    }
    else{
      this.ultlabelInfo4 = false;
    }
  }
  if(id==5){
    if(this.ultlabelInfo5 == false){
      this.ultlabelInfo5 = true;
    }
    else{
      this.ultlabelInfo5 = false;
    }
  }
}
ClickToOpenULTLearnMore(id){
  if(id==1){
    if(this.ultlearnMore == false){
      this.ultlearnMore = true;
    }
    else{
      this.ultlearnMore = false;
    }
  }
  if(id==2){
    if(this.ultlearnMore2 == false){
      this.ultlearnMore2 = true;
    }
    else{
      this.ultlearnMore2 = false;
    }
  }
  if(id==3){
    if(this.ultlearnMore3 == false){
      this.ultlearnMore3 = true;
    }
    else{
      this.ultlearnMore3 = false;
    }
  }
  if(id==4){
    if(this.ultlearnMore4 == false){
      this.ultlearnMore4 = true;
    }
    else{
      this.ultlearnMore4 = false;
    }
  }
  if(id==5){
    if(this.ultlearnMore5 == false){
      this.ultlearnMore5 = true;
    }
    else{
      this.ultlearnMore5 = false;
    }
  }
}
ClickToOpenULTCustomerReviews(id){
  if(id==1){
    if(this.ultcustomerReviews == false){
      this.ultcustomerReviews = true;
      this.GetAllJudgMeProducts('11200');
    }
    else{
      this.ultcustomerReviews = false;
    }
  }
  if(id==2){
    if(this.ultcustomerReviews2 == false){
      this.ultcustomerReviews2 = true;
      this.GetAllJudgMeProducts('11270');
    }
    else{
      this.ultcustomerReviews2 = false;
    }
  }
  if(id==3){
    if(this.ultcustomerReviews3 == false){
      this.ultcustomerReviews3 = true;
      this.GetAllJudgMeProducts('11235');
    }
    else{
      this.ultcustomerReviews3 = false;
    }
  }
  if(id==4){
    if(this.ultcustomerReviews4 == false){
      this.ultcustomerReviews4 = true;
      this.GetAllJudgMeProducts('11208');
    }
    else{
      this.ultcustomerReviews4 = false;
    }
  }
  if(id==5){
    if(this.ultcustomerReviews5 == false){
      this.ultcustomerReviews5 = true;
      this.GetAllJudgMeProducts('11210');
    }
    else{
      this.ultcustomerReviews5 = false;
    }
  }
}
getULTProduct(webCategoryId) {
  if (webCategoryId == 504) {
      const request = {
        'EnrollmentType': '',
        'CustomerType': 22,
        'WebCategoryId': webCategoryId,
        'isUpgradeCustomer': false
      };
      this._restService.getProducts(request).subscribe((res: any) => {
        this.ULTLifestyleProducts = res.Items;
        this.loadInitialItems();
        this.ultPackCarousel(11240);
        localStorage.setItem('ULTLifestyleProducts', JSON.stringify(this.ULTLifestyleProducts));
      });
  }
  if (webCategoryId == 644) {
      const request = {
        'EnrollmentType': '',
        'CustomerType': 22,
        'WebCategoryId': webCategoryId,
        'isUpgradeCustomer': false
      };
      this._restService.getProducts(request).subscribe((res: any) => {
        this.ULTBrainProducts = res.Items;
        localStorage.setItem('ULTBrainProducts', JSON.stringify(this.ULTBrainProducts));
        this.ultPackCarousel(11248);
      });
  }
  if (webCategoryId == 510) {
      const request = {
        'EnrollmentType': '',
        'CustomerType': 22,
        'WebCategoryId': webCategoryId,
        'isUpgradeCustomer': false
      };
      this._restService.getProducts(request).subscribe((res: any) => {
        this.ULTCOllagenSupportProducts = res.Items;
        localStorage.setItem('ULTCOllagenSupportProducts', JSON.stringify(this.ULTCOllagenSupportProducts));
        this.ultPackCarousel(11243);
      });
  }
  if (webCategoryId == 509) {
      const request = {
        'EnrollmentType': '',
        'CustomerType': 22,
        'WebCategoryId': webCategoryId,
        'isUpgradeCustomer': false
      };
      this._restService.getProducts(request).subscribe((res: any) => {
        this.ULTImmuneProducts = res.Items;
        localStorage.setItem('ULTImmuneProducts', JSON.stringify(this.ULTImmuneProducts));
        this.ultPackCarousel(11242);
      });
  }
  if (webCategoryId == 508) {
      const request = {
        'EnrollmentType': '',
        'CustomerType': 22,
        'WebCategoryId': webCategoryId,
        'isUpgradeCustomer': false
      };
      this._restService.getProducts(request).subscribe((res: any) => {
        this.ULTPerformanceProducts = res.Items;
        localStorage.setItem('ULTPerformanceProducts', JSON.stringify(this.ULTPerformanceProducts));
        this.ultPackCarousel(11241);
      });
    }
  }
  loadInitialItems() {
    this.productImage('11240');
    this.LongDetail('11240');
    this.productSectionInfo(11, '#749472');
    this.productsPrices('11240');
    this.packType(1);
  }
  ultPackCarousel(itemCode)
  {
    switch(itemCode) {
      case 11240:
        this.SubscribeProduct = {id: 11240, sortBy:0, heading: 'Ultimate Lifestyle<br> Transformation (ULT)',imageUrl:  this.productsImage('11240'),color:  '#749472',newpackLogo: '', starURL: '../../../assets/images/ultlifestyleLanding/green_Star.png', reviewText: '“6 months ago I decided to make a change! No more excuses and no more waiting for the perfect day. I have found that nutrition partnered with just moving my body has changed my life...”'};
        break;
      case 11248:
        this.SubscribeProduct = {id: 11248, sortBy:1, heading: 'ULT +<br>Brain',imageUrl:  this.productsImage('11248'),color:  '#1C4BC0',newpackLogo: '../../../assets/images/ultlifestyleLanding/newPack_logo.png',starURL: '../../../assets/images/ultlifestyleLanding/blue_star.png',reviewText: "“I'm absolutely thrilled to commence this journey with the Ult + Brain Pack, dedicated to enhancing my cognitive health and unlocking boundless potential for both my body and mind. Remarkably, within just a few days of starting, I've noticed a significant surge in my attention to detail.”"};
        break;
      case 11243:
        this.SubscribeProduct = {id: 11243, sortBy:2, heading: 'ULT +<br>Collagen Support',imageUrl:  this.productsImage('11243'),color:  '#A33FA3',newpackLogo: '',starURL: '../../../assets/images/ultlifestyleLanding/pink_Star.png',reviewText: '“Wowwww this pack is amazing ! Highly recommand ! My skin is glowing, everyone around me is asking me what is my secret!..”'};
        break;
      case 11242:
        this.SubscribeProduct = {id: 11242, sortBy:3, heading: 'ULT +<br>Immune',imageUrl:  this.productsImage('11242'),color:  '#BF8A56',newpackLogo: '',starURL: '../../../assets/images/ultlifestyleLanding/sandColor_Star.png',reviewText: '“This is so so essential for my health as someone who has a genetic mutation preventing my body from creating many antibodies and white blood cells. I must support my immune system in all other ways that I can!”'}
        break;
      case 11241:
        this.SubscribeProduct = {id: 11241, sortBy:4, heading: 'ULT +<br>Performance',imageUrl:  this.productsImage('11241'),color:  '#53A3D7',newpackLogo: '',starURL: '../../../assets/images/ultlifestyleLanding/lightBlue_Star.png',reviewText: '“I’ve been healthy and active the past 30 years of my life but getting on the ULT + performance kit really took my mental and physical health to a level I never could have dreamed of...”'}
        break;
      default:
    }
    this.SubscribeProducts.push(this.SubscribeProduct);
    localStorage.setItem('SubscribeProductsSlick',JSON.stringify(this.SubscribeProducts));
    this.getColors(0);
  }
}