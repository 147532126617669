import { Component, Inject, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { Observable, Subject, fromEvent, interval, merge } from 'rxjs';
import { _ROUTER_CONFIG } from '@ngrx/router-store/src/router_store_module';
import * as _ from 'underscore';
import { EnrollSessionService } from './shared/enrollSession.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { RestService } from './shared/rest.service';
import { OrderService } from './shared/order.service';
import { ToastrService } from 'ngx-toastr';
import { SharedMethodService } from './shared/sharedMethod.service';
import { getItemCodeByShopifyId } from 'app/constants'
import { AuthService } from './shared/auth.service';
import { CalculateRetailInitialOrderService } from './shared/calculateRetailInitialOrder.service';
import { CalculateRetailSmartOrderService } from './shared/CalculateRetailSmartOrder.service';
import { CookieComponent } from './shared/cookie-dialog/cookie-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LinkTracking } from './constants';
import { map, mapTo, startWith, switchMap, takeWhile } from 'rxjs/operators';
@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    templateUrl: any;
    screenWidth: any;
    mobileView: any;
    mediumScreen: any;
    interval: any;
    webalias: string;
    autoOrderList: any =[];
    orderList: any = [];
    prods: any;
    possible:string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~`";
    lengthOfCode:number = 10;
    dialogRef: any;
    private _unsubscribeAll: Subject<any>;
    active$: Observable<boolean>;
    private alive = true;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _activateRoute: ActivatedRoute,
        private _restService: RestService,
        private _platform: Platform,
        private ref: ChangeDetectorRef,
        public cookieService: CookieService,
        public _router: Router,
        public _enrollSession: EnrollSessionService,
        private _toastr: ToastrService,
        public _sharedMethodService: SharedMethodService,
        public _orderService: OrderService,
        public _auth: AuthService,
        private _matDialog: MatDialog,
        private _calculateRetailInitialOrder: CalculateRetailInitialOrderService,
        private _calculateRetailSmartOrder: CalculateRetailSmartOrderService,
        
    ) {
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }
        this._unsubscribeAll = new Subject();
        localStorage.setItem('SessionKey', this.makeRandom(this.lengthOfCode, this.possible));
        this._enrollSession.SessionKey = localStorage.getItem('SessionKey');
        this.interval = setInterval(() => {
            if(localStorage.getItem('SessionKey')===this._enrollSession.SessionKey)
            {
                sessionStorage.setItem('submitApplicationData', JSON.stringify(this._enrollSession.submitApplicationData));
                sessionStorage.setItem('editTransformationPack', this._enrollSession.editTransformationPack);
                localStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
            }
            this.ref.markForCheck();
        }, 1000);
        localStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
        if(this._auth.getToken())
        {
            let LoggedInUser = JSON.parse(localStorage.getItem("LoggedInUserData"));
            sessionStorage.getItem('balanceFieldPoint') !== null && sessionStorage.getItem('balanceFieldPoint') !== undefined ? (this._sharedMethodService.balanceField = sessionStorage.getItem('balanceFieldPoint')) : this._sharedMethodService.getPointAccount(LoggedInUser?.CustomerID);
        }
        if (event instanceof NavigationEnd) {
            try {
                const pathArray = event.urlAfterRedirects.split("/");
                const pageName = pathArray[pathArray.length - 1];
                const data = JSON.stringify({
                    pageName: pageName,
                    pageURL: location.href,
                });
                window["ZTM"].setStats(LinkTracking.companyName, "PageView", data);
            } catch (error) {}
        }
    }


    ngOnInit(): void {
        var urlParams = new URLSearchParams(window.location.search);
        let products = urlParams?.get('products');
        let IsIrtItemBioMedic = JSON.parse(products)?.find(item => (item.itemcode == '2270-3'));
        this.onResize();
        this.giftcardFunctionality();
        this.collection1Routing();
        this.openCookiePopup();
        this.PageReloadWithoutEvent();
        if(IsIrtItemBioMedic){
            this.BioMedic3Functionality();
        }
        else{
            this.linkBuilderProduct_AddtoCart();
            this.CantePackFunctionality();
        }
    }

    @HostListener('window:beforeunload')
    onBeforeUnload() {
        clearTimeout(this.interval)
        return true;
    }
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.screenWidth = window.innerWidth;
        if (this.screenWidth < 1100) {
            this.mediumScreen = true;
        }
        else {
            this.mediumScreen = false;
        }
        sessionStorage.setItem('MobileView', JSON.stringify(this._enrollSession.mobileView));
        this._enrollSession.mediumScreen = this.mediumScreen;
        sessionStorage.setItem('mediumScreen', JSON.stringify(this._enrollSession.mediumScreen));
        this._enrollSession.mobileView = localStorage.getItem('mobileView') == "true" ? true : false;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    
    onActivate(event) {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    giftcardFunctionality() {
        this._enrollSession.data.RetailEnroll = this._enrollSession.data.RetailEnroll || {};
        this._enrollSession.data.RetailOrderSummary = this._enrollSession.data.RetailOrderSummary || {};
        var urlParams = new URLSearchParams(window.location.search);
        this.webalias = urlParams.get('giftcard');
        this.webalias = this._activateRoute.snapshot.queryParamMap.get('giftcard') || sessionStorage.getItem('giftCardName') || this.webalias;
        if (this.webalias && this.webalias != '' && this.webalias != null && this.webalias != "null") {
            const request = '?WebAlias=' + this.webalias;
            this._restService.GetCustomerSite(request).subscribe((result: any) => {
                if (result.Data != null) {
                    this._enrollSession.data.webAlias = this.webalias;
                    sessionStorage.setItem('giftCardName', this._enrollSession.data.webAlias);
                    this._enrollSession.data.RetailEnroll.webAlias = result.Data.firstNameField + ' ' + result.Data.lastNameField;
                } else {
                    this._toastr.error('Invalid WebAlias.', 'Error');
                }
            }, error => {
                this._toastr.error('Somthing is wrong Please try again', 'Error');
            });
        }
    }
    private linkBuilderProduct_AddtoCart() {
        var urlParams = new URLSearchParams(window.location.search);
        let products = urlParams.get('products');
        this.prods = urlParams.get('prods');
        if (products) {
            this._orderService.getAllTypeProd().then((items) => {
                let IsIrtItem = JSON.parse(products).find(item => (item.itemcode == 11200 || item.itemcode == 11270 || item.itemcode == 11210 || item.itemcode == 11235 || item.itemcode == 11208 || item.itemcode == 11154));
                if (IsIrtItem && Object.keys(IsIrtItem).length > 0) {
                    this._router.navigate(['/IRT']);
                } else {
                    this._router.navigate(['/']);
                }
                JSON.parse(products).forEach((queryItem) => {
                    _.each(queryItem.orderqty > 0 && items[1], (item) => {
                        if ((item.ItemCode == queryItem.itemcode)) {
                            this.orderList.push({
                                ItemCode: item.ItemCode,
                                Description: item.Description,
                                Quantity: queryItem.orderqty ? queryItem.orderqty : 1,
                                Price: item.Price,
                                RetailPrice: item.Other1Price,
                                ShippingPrice: item.ShippingPrice,
                                TinyImage: item.TinyPicture,
                                SmallImage: item.SmallPicture,
                                BV: item.BusinessVolume,
                                CV: item.CommissionableVolume,
                                TaxablePrice: item.TaxablePrice,
                                LongDetail: item.LongDetail,
                                LongDetail2: item.LongDetail2,
                                LongDetail3: item.LongDetail3,
                                LongDetail4: item.LongDetail4,
                                ParentId: item.ParentId,
                                IsUSOnly: item.IsUSOnly ? item.IsUSOnly : false
                            });
                            sessionStorage.setItem("FromShareLinkCart","true");
                        }
                    });
                    _.each(queryItem.autoshipqty > 0 && items[1], (item) => {
                        if ((item.ItemCode == queryItem.itemcode)) {
                            this.autoOrderList.push({
                                ItemCode: item.ItemCode,
                                Description: item.Description,
                                Quantity: queryItem.autoshipqty ? queryItem.autoshipqty : 1,
                                Price: item.Price,
                                RetailPrice: item.Other1Price,
                                ShippingPrice: item.ShippingPrice,
                                TinyImage: item.TinyPicture,
                                SmallImage: item.SmallPicture,
                                BV: item.BusinessVolume,
                                CV: item.CommissionableVolume,
                                TaxablePrice: item.TaxablePrice,
                                LongDetail: item.LongDetail,
                                LongDetail2: item.LongDetail2,
                                LongDetail3: item.LongDetail3,
                                LongDetail4: item.LongDetail4,
                                ParentId: item.ParentId,
                                IsUSOnly: item.IsUSOnly ? item.IsUSOnly : false
                            });
                        }
                    });
                });
                this._enrollSession.data.RetailOrderSummary.SmartOrders = this.autoOrderList;
                this._enrollSession.data.RetailOrderSummary.InitialOrders = this.orderList;
                if(this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0){
                    this._calculateRetailSmartOrder.calculate(this._sharedMethodService.finalShippingMethod);
                }
                if(this._enrollSession.data.RetailOrderSummary.InitialOrders.length > 0){
                    this._calculateRetailInitialOrder.calculate(this._sharedMethodService.finalShippingMethod);
                }
                if(window.innerWidth < 550){
                    this._sharedMethodService.openMobileSummaryCart();
                  }
                else{
                    this._sharedMethodService.toggleSidenavforaddtocart();
                }
            });
        }
        else if (this.prods) {
            let products = this.prods.split(',');
            if (products.length > 0) {
                this._orderService.getAllTypeProd().then((items) => {
                    _.each(items[1], (item) => {
                        _.each(products, (prod) => {
                            let itm = prod.split(':');
                            if (itm.length > 0) {
                                let i = getItemCodeByShopifyId(itm[0]);
                                if (item.ItemCode == i) {
                                    this._sharedMethodService.ContinueRetailOrder(item, itm[1], 2);
                                    sessionStorage.setItem("FromShareLinkCart","true");
                                    this._router.navigate(['/']);
                                    if(window.innerWidth < 550){
                                        this._sharedMethodService.openMobileSummaryCart();
                                      }
                                    else{
                                        this._sharedMethodService.toggleSidenavforaddtocart();
                                    }
                                }
                            }
                        });
                    });
                });
            }
        }
    }
    private collection1Routing()
    {
        let urlRoute = window. location.pathname;
        if (urlRoute=='/pages/lifestyle-transformation') {
            this._router.navigate(['/Collection1']);
        }
    }
    private makeRandom(lengthOfCode: number, possible: string) {
        let text = "";
        for (let i = 0; i < lengthOfCode; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
    private openCookiePopup()
    {
        if(localStorage.getItem('isAcceptCookie')!='true'){
            this.dialogRef = this._matDialog.open(CookieComponent, {
                width: '550px',
                maxWidth: '550px',
                height: '456px',
                panelClass: '',
                data: {
                    
                },
                disableClose: true
            });
        }
    }
    pageReload() {
        this.cookieService.delete('access_token', '/');
        this.cookieService.delete('X-Auth', '/');
        this.cookieService.deleteAll('/');
        sessionStorage.setItem("IsSSOLogin", 'false');
        let FromShareLinkCart = sessionStorage.getItem("FromShareLinkCart");
        this._enrollSession.data.RetailOrderSummary = FromShareLinkCart == "true" ? this._enrollSession.data.RetailOrderSummary : {};
        this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
        this._enrollSession.data.IsLoggedIn = false;
        this._enrollSession.data.LoggedInUserData = {};
        this._enrollSession.data.priceType = 0;
        sessionStorage.setItem('onUltLifestyleFlow', 'false');
        this._router.navigate(['/homepage']).then(() => {
            if (window.localStorage && window.sessionStorage) {
                window.localStorage.removeItem('user');
                window.localStorage.clear();
                window.sessionStorage.clear();
            }
            window.location.reload();
        });
        localStorage.setItem("isExistingAutoship", "false");
        alert("Your session has been expired!!");
    }
    private PageReloadWithoutEvent() {
        const events = ['mousemove', 'keydown', 'click','scroll','mouseenter', 'mouseleave', 'mouseup', 'mousedown'];
        const currentTime = new Date();
        const timeoutTime = new Date(currentTime.getTime() + 3600000);
        this.active$ = merge(
            ...events.map((eventName) =>
                fromEvent(document, eventName).pipe(mapTo(true))
            )
        ).pipe(
            startWith(true),
            switchMap(() => interval(timeoutTime.getTime() - currentTime.getTime())),
            map(() => false),
            takeWhile(() => this.alive)
        );
        this.active$.subscribe((isActive) => {
            if (!isActive) {
                this.pageReload();
            }
        });
    }
    private CantePackFunctionality()
    {
        var urlParams = new URLSearchParams(window.location.search);
        let site = urlParams.get('site');
        if (site=="cante") {
            this._orderService.getCanteItems().then((items:any)=>
            {
                _.each(items, (item) => {
                    if(item.ItemCode == 12140)
                    {
                        sessionStorage.setItem("FromCanteSite","true");
                        this._sharedMethodService.ContinueRetailOrder(item, 1, 2);
                    }
                    if(sessionStorage.getItem("AllProducts472"))
                    {
                        this._router.navigate(['/cantepack']);
                    }
                })
            });
        } else {
            return
        }
    }
    private BioMedic3Functionality()
    {
        const customertype = JSON.parse(this._auth.getUserData()) || {}
        var urlParams = new URLSearchParams(window.location.search);
        let products = urlParams.get('products');
        let IsIrtItem = JSON.parse(products)?.find(item => (item.itemcode == '2270-3'));
        sessionStorage.setItem("FromBioMedic3Site","false");
        if(IsIrtItem && Object.keys(IsIrtItem).length > 0)
        {
            const request = {
                'EnrollmentType': '',
                'CustomerType': this._auth.isLoggedIn() ? customertype : 22,
                'WebCategoryId': 654,
                'isUpgradeCustomer': false,
                'IsSubscriptionOrder': false,
                'PriceType': ''
            };
            this._restService.getProducts(request).subscribe((items: any) => {
                let productList = items.Items;
                let biomedic3 = productList.find(item => (item.ItemCode === '2270-3'));
                if (biomedic3) {
                    sessionStorage.setItem("FromBioMedic3Site","true");
                    this._sharedMethodService.ContinueRetailOrder(biomedic3, 1, 2);
                    this._router.navigate(['/IRT']);
                }
            });
        }
    }
}
