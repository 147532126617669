<div class="pt-5" class="design">
    <div class="container">
        <div class="row" fxFlex="100" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-evenly start">
            <div class="col m-2">
                <p style="margin-bottom:0">2610 Homestead Pl, <br>Rancho Dominguez, CA 90220</p>
                <br>
                <p>(888)747-6733</p>
                <img src="../../../../assets/img/logo.png" alt="" style="max-width: 80px;">
            </div>
            <div class="col m-2" style="line-height: 2rem;">
                <a href="documents/support">Support</a>
                <br>
                <a href="documents/shipping">Shipping</a>
                <br>
                <a href="documents/return">Return Policy</a>
                <br>
                <a href="documents/privacy">Privacy Policy</a>
            </div>
            <div class="col m-2" style="line-height: 2rem;">
                <a href="documents/incentives">Special Incentives</a>
                <br>
                <a routerLink="/Calendar">Events</a>
                <br>
                <a href="http://blog.puriumcorp.com/" target="_blank">Purium Blog</a>
                <br>
                <a href="/IncomeOpportunity" target="_blank">Income Opportunity</a>
                <br>
                <a routerLink="/getnews">Get News</a>
            </div>
        </div>
    </div>
</div>