import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'faq'
})
export class FaqPipe implements PipeTransform {
  transform(data: any[], searchText: string): any[] {
    if (!data || !searchText) {
      return data;
    }

    searchText = searchText.toLowerCase();

    return data.filter(faq => {
      return faq.question.toLowerCase().includes(searchText) || faq.answer.toLowerCase().includes(searchText);
    });
  }
}
