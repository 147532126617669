<section *ngIf="siteType!='ultlifestyle'"class="z-indexxx" [ngClass]="{'withoutSharelinkCart':(currentPage() && !_sharedMethodService.giftcardNameMethod()), 'sharelinkCart': (currentPage() && (router.url.includes('homepage') || router.url.includes('Ultlifestyle'))  && _sharedMethodService.giftcardNameMethod())}">
    <ng-container *ngIf="_enrollSession.SummaryCartMobOnly && currentPage()">
        <div class="order-summary-div-mobile order-summary-mobile">
            <div>
                <div class="w-100-div" fxLayoutAlign.xs="end end" style="padding: 12px;">
                    <div><span class="c-green f-s-14 f-w-600 remove-item" (click)="closeMobileSummaryCart()">Close</span>
                    </div>
                </div>
                <div class="divContainermobile" [ngClass]="{'divContainermobileShareLink': (_sharedMethodService.giftcardNameMethod() && (router.url.includes('homepage') || router.url.includes('Ultlifestyle')))}">
                <ng-container
                    *ngIf="(!_enrollSession.data.RetailOrderSummary || (!_enrollSession.data.RetailOrderSummary.Enrollpack || _enrollSession.data.RetailOrderSummary.Enrollpack.length == 0) && (!_enrollSession.data.RetailOrderSummary.InitialOrders || _enrollSession.data.RetailOrderSummary.InitialOrders.length == 0) && (!_enrollSession.data.RetailOrderSummary.SmartOrders || _enrollSession.data.RetailOrderSummary.SmartOrders.length == 0))">
                    <div class="order-summary-divider"></div>
                    <div class="order-summary-totals-div-block">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">SubTotal</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Tax</div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total QV
                            </div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total BV
                            </div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">---</div>
                            <div class="order-summary-text">---</div>
                            <div class="order-summary-text">---</div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">---</div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">---</div>
                        </div>
                    </div>
                    <div class="order-summary-divider"></div>
                    <div class="order-summary-totals-div-block">
                        <div class="order-summary-totals-text-div-block order-summary-labels">
                            <div class="order-summary-text order-summary-text-total">Your Total:</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text order-summary-text-total">---</div>
                        </div>
                    </div>
                </ng-container>
                <div class="order-summary-product-div-block"
                    *ngIf="_enrollSession.data.RetailOrderSummary && ((_enrollSession.data.RetailOrderSummary.Enrollpack && _enrollSession.data.RetailOrderSummary.Enrollpack.length > 0) || (_enrollSession.data.RetailOrderSummary.InitialOrders && _enrollSession.data.RetailOrderSummary.InitialOrders.length > 0) || (_enrollSession.data.RetailOrderSummary.SmartOrders && _enrollSession.data.RetailOrderSummary.SmartOrders.length > 0))">
                    <div class="w-100-div"
                        *ngIf="_enrollSession.data.RetailOrderSummary && (_enrollSession.data.RetailOrderSummary?.Enrollpack && _enrollSession.data.RetailOrderSummary?.Enrollpack.length > 0)">
                        <div class="w-100 ">
                            <div class=" order-summary-h1" [textContent]="packSectionTitle">
                            </div>
                        </div>
                    </div>
                    <div
                        *ngFor="let item of _enrollSession.data.RetailOrderSummary.Enrollpack | sortBy: 'asc':'-Price'; let i = index">
                        <div class="w-layout-grid order-summary-grid-smart">
                            <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text cursor-pointer"></div>
                            <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId"
                                src="{{item.SmallImage? item.SmallImage : '../../../assets/img/noimage.png'}}"
                                loading="lazy" id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                class="order-summary-img img-box" [ngClass]="{'cursor-pointer': (FromShareLinkCart === 'true')}" (click)="productInfo(item)">
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text cursor-pointer" (click)="productInfo(item)">{{item.Description}}</div>
                            <div class="quantityMeasureBox" id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                fxLayoutAlign="start center"
                                *ngIf="currentRouterPath !='/Confirmation' && item.ItemCode != '7800GC'">
                                <div class="input-group" fxLayout="row" fxLayoutAlign="center baseline"
                                    *ngIf="(!item.ParentId)">
                                    <div class="input-group-btn" *ngIf="item.Quantity > 1">
                                        <button mat-button-icon type="button" fxLayoutAlign="center center">
                                            <mat-icon
                                                (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,1)">
                                                remove</mat-icon>
                                        </button>
                                    </div>

                                    <div *ngIf="item.Quantity == 1 && i == 0" class="input-control" fxLayoutAlign="center center">
                                        <span fxLayoutAlign="center center" class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'transformation')">
                                            <img src="../../../assets/icons/bin.png" alt="" class="cart-w-16"></span>
                                    </div>
                                     
                                    <div class="input-control" fxLayoutAlign="center center">
                                        <span>{{item.Quantity}}</span>
                                    </div>
                                    <div class="input-group-btn">
                                        <button mat-button-icon type="button" fxLayoutAlign="center center">
                                            <mat-icon class="line-h-0"
                                                (click)="_sharedMethodService.ContinueRetailOrder(item,1,1);" >add
                                            </mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <div class="input-group" fxLayout="row" fxLayoutAlign="center baseline"
                                    *ngIf="item.ParentId == item.ItemCode && item.ParentId">
                                    <div *ngIf="i == 0" class="input-control" fxLayoutAlign="center center">
                                        <span fxLayoutAlign="center center" class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'transformation')">
                                            <img src="../../../assets/icons/bin.png" alt="" class="cart-w-16"></span>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div class="input-control" fxLayoutAlign="center center">
                                        <span>{{item.Quantity}}</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="currentRouterPath !='/Confirmation' && ( item.ItemCode=='7800GC' || (item.ParentId != item.ItemCode && item.ParentId))" id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                fxLayout="column" fxLayoutAlign="start center">
                                <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                    <div class="input-remove" fxLayoutAlign="center center">
                                        <span>{{item.Quantity}}</span>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price"
                                    *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                                    ${{item.Price |number :
                                    '1.2-2'}} USD</div>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price"
                                    *ngIf="(_enrollSession.data.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType!=10)">
                                    ${{item.Other1Price |number :
                                    '1.2-2'}} USD</div>
                            </ng-container>
                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                *ngIf="_sharedMethodService.validCustomerType() && (item.ParentId == item.ItemCode || !item.ParentId) && item.ItemCode!='11050-FREE' && item.ItemCode!='11050' && item.ItemCode!='11020'"
                                class="order-summary-product-price">{{item.BV || 0}} BV</div>
                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                *ngIf="_sharedMethodService.validCustomerType() && (item.ParentId == item.ItemCode || !item.ParentId) &&  ( item.ItemCode=='11050-FREE' || item.ItemCode=='11050' || item.ItemCode=='11020')"
                                class="order-summary-product-price">{{item.BV || 0}} QV</div>
                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        class="order-summary-product-price text-size"
                                        *ngIf="item.ParentId == item.ItemCode && item.ParentId">
                                        *Customizable packs have to be added individually</div>
                        </div>
                    </div>

                    <!-- Colorado Retail Delivery Fees -->
                    <div class="w-layout-grid order-summary-grid"
                        *ngIf="_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.price"
                        style="margin-bottom: 10px;">
                        <div fxLayoutAlign="center center">
                            <img src="../../../assets/images/image/retail-delivery-fees.png" loading="lazy"
                                id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                class="order-summary-img img-box delivery-fee-image">
                        </div>
                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                            class="order-summary-product-text">{{
                            _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees.description
                            }}</div>

                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                            class="order-summary-product-text" fxLayoutAlign="center start"> {{
                            _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees.quantity
                            }} </div>

                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                            class="order-summary-product-price">${{
                            _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees.price
                            |number : '1.2-2'}} USD</div>

                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                            class="order-summary-product-price cursor-pointer">
                        </div>
                    </div>

                </div>
                <div class="order-summary-product-div-block"
                    *ngIf="_enrollSession.data.RetailOrderSummary?.InitialOrders && _enrollSession.data.RetailOrderSummary?.InitialOrders.length > 0">
                    <div class="order-summary-divider"
                        *ngIf="_enrollSession.data.RetailOrderSummary && (_enrollSession.data.RetailOrderSummary?.Enrollpack && _enrollSession.data.RetailOrderSummary?.Enrollpack.length > 0)">
                    </div>
                    <div class="w-100-div m-t-10">
                        <div class="w-100">
                            <div class=" order-summary-h1">Today&#x27;s Order</div>
                        </div>
                    </div>
                    <div *ngFor="let item of _enrollSession.data.RetailOrderSummary.InitialOrders; let i = index">
                        <div style="display: flex;justify-content: center;">
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                            class="order-summary-product-text cursor-pointer text-center" (click)="productInfo(item)">
                            {{item.Description}}</div>
                            <div *ngIf="currentRouterPath =='/Confirmation' || item.ItemCode == '7800GC'" id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                fxLayout="column" fxLayoutAlign="center start" style="margin-left: auto;">
                                <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                    <div class="input-remove" fxLayoutAlign="center center">
                                        <span>{{item.Quantity}}</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="currentRouterPath !='/Confirmation' && (item.ParentId != item.ItemCode && item.ParentId)" id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                fxLayout="column" fxLayoutAlign="center start" style="margin-left: auto;">
                                <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                    <div class="input-remove" fxLayoutAlign="center center">
                                        <span>{{item.Quantity}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style="display: flex;">
                            <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId"
                                src="{{item.SmallImage? item.SmallImage : '../../../assets/img/noimage.png'}}"
                                loading="lazy" id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                class="order-summary-img img-box" [ngClass]="{'cursor-pointer': (FromShareLinkCart === 'true')}" (click)="productInfo(item)">
                            <div class="w-layout-grid order-summary-grid QuantityDescriptionName" [ngClass]="{'order-summary-mobile-grid2': item?.ItemCode === '7800GC','order-summary-mobile-grid4': (item.ItemCode !== '7800GC' && _sharedMethodService.validCustomerType()), 'order-summary-mobile-grid3':(item.ItemCode !== '7800GC' && !_sharedMethodService.validCustomerType())}">
                                <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                    id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text cursor-pointer"></div>
                                    <div class="quantityMeasureBox" id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                    fxLayoutAlign="start center"
                                    *ngIf="currentRouterPath !='/Confirmation' && item.ItemCode != '7800GC'">
                                    <div class="input-group" fxLayout="row" fxLayoutAlign="center baseline"
                                        *ngIf="(!item.ParentId)">
                                        <div class="input-group-btn" *ngIf="item.Quantity > 1">
                                            <button mat-button-icon type="button" fxLayoutAlign="center center">
                                                <mat-icon
                                                    (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,2)">
                                                    remove</mat-icon>
                                            </button>
                                        </div>

                                        <div *ngIf="item.Quantity == 1" class="input-control" fxLayoutAlign="center center">
                                            <span fxLayoutAlign="center center" class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'todayOrder')">
                                                <img src="../../../assets/icons/bin.png" alt="" class="cart-w-16"></span>
                                        </div>
                                         
                                        <div class="input-control" fxLayoutAlign="center center">
                                            <span>{{item.Quantity}}</span>
                                        </div>
                                        <div class="input-group-btn">
                                            <button mat-button-icon type="button" fxLayoutAlign="center center">
                                                <mat-icon class="line-h-0"
                                                    (click)="_sharedMethodService.ContinueRetailOrder(item,1,2);" >add
                                                </mat-icon>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="input-group" fxLayout="row" fxLayoutAlign="center baseline"
                                        *ngIf="item.ParentId == item.ItemCode && item.ParentId">
                                        <div class="input-control" fxLayoutAlign="center center">
                                            <span fxLayoutAlign="center center" class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'todayOrder')">
                                                <img src="../../../assets/icons/bin.png" alt="" class="cart-w-20"></span>
                                        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div class="input-control" fxLayoutAlign="center center">
                                            <span>{{item.Quantity}}</span>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        class="order-summary-product-price"
                                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                                        ${{item?.Price |number : '1.2-2'}}
                                        USD</div>
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        class="order-summary-product-price"
                                        *ngIf="(_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10)">
                                        ${{getItemPrice(item) |number : '1.2-2'}}
                                        USD
                                    </div>
                                </ng-container>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    *ngIf="_sharedMethodService.validCustomerType() && (item.ParentId == item.ItemCode || !item.ParentId)"
                                    class="order-summary-product-price">{{item.BV || 0}} BV</div>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price text-size"
                                    *ngIf="item.ParentId == item.ItemCode && item.ParentId">
                                    *Customizable packs have to be added individually</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="order-summary-divider"></div>
                <div
                    *ngIf="_enrollSession.data.RetailOrderSummary?.Enrollpack && _enrollSession.data.RetailOrderSummary?.Enrollpack.length > 0 || _enrollSession.data.RetailOrderSummary?.InitialOrders && _enrollSession.data.RetailOrderSummary?.InitialOrders.length > 0  || _enrollSession.data.RetailOrderSummary?.AddOnOrders && _enrollSession.data.RetailOrderSummary?.AddOnOrders.length > 0">
                    <div class="order-summary-totals-div-block" *ngIf="_calculateAutoOrder.getIsCallCalculateOrder()">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">SubTotal</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Tax</div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total QV
                            </div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total BV
                            </div>
                            <div class="order-summary-text">Your Total</div>
                            <div class="order-summary-text" *ngIf="_calculateRetailOrderService.isSelected">Reward Points Redeemed</div>
                            <div class="order-summary-text" *ngIf="_calculateRetailOrderService.isSelected">Credit Card Payment Due</div>
                            <div class="order-summary-text italic-text" id="totalsaving">Loyalty Discount Savings</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal |number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.ShippingTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Tax|number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                <span>{{_enrollSession.data?.RetailOrderSummary.InitialOrderDetails?.BV}} </span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                <span>{{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.CV}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total|number :
                                    '1.2-2'}} USD</span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_calculateRetailOrderService.isSelected">
                                <span>-(${{_calculateRetailOrderService.DiscountValue |number : '1.2-2'}})</span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_calculateRetailOrderService.isSelected">
                                <span>${{_calculateRetailOrderService.totalRemainingBalance |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r" >
                                <span id="totalsaving"> <b> ${{ getDiscountTotal() | number : '1.2-2'}} USD</b></span>
                            </div>
                        </div>
                    </div>

                    <div class="order-summary-divider"></div>
                    <div class="order-summary-totals-div-block" *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder()">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">SubTotal</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Tax</div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total QV
                            </div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total BV
                            </div>
                            <div class="order-summary-text">Your Total</div>
                            <div class="order-summary-text" *ngIf="_calculateRetailOrderService.isSelected">Reward Points Redeemed</div>
                                <div class="order-summary-text" *ngIf="_calculateRetailOrderService.isSelected">Credit Card Payment Due</div>
                                <div class="order-summary-text italic-text" id="totalsaving">Loyalty Discount Savings</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal |number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.ShippingTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Tax|number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                <span>{{_enrollSession.data?.RetailOrderSummary.InitialOrderDetails?.BV}} </span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                <span>{{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.CV}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total|number :
                                    '1.2-2'}} USD</span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_calculateRetailOrderService.isSelected">
                                <span>-(${{_calculateRetailOrderService.DiscountValue |number : '1.2-2'}})</span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_calculateRetailOrderService.isSelected">
                                <span>${{_calculateRetailOrderService.totalRemainingBalance |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r" >
                                <span id="totalsaving"> <b> ${{ getDiscountTotal() | number : '1.2-2'}} USD</b></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="_enrollSession.data.RetailOrderSummary?.SmartOrders && _enrollSession.data.RetailOrderSummary?.SmartOrders.length>0">
                    <div class="order-summary-divider"></div>
                    <div class="order-summary-product-div-block">
                        <div class="w-100-div" fxLayout="column">
                            <div class="w-100 ">
                                <div class="order-summary-h1" *ngIf="isLoginCartButton()">Your Current Smart Order Subscription
                                </div>
                                <div class="order-summary-h1" *ngIf="!isLoginCartButton()">Smart Order Subscription
                                </div>
                            </div>
                            <div class="smartOrderDateBoxCart " fxLayout="column">
                                <div class="p-r-10 text-center">
                                    <label>Your Smart Order Date:</label>
                                </div>
                            <div class="text-center">
                                {{_enrollSession.data.smartOrderDate ? _enrollSession.data.smartOrderDate : (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 ? smartOrderDateForLifestyle : '--/--/----')}}
                            </div>
                        </div>
                            <div class="text-center">
                                <label>Your Smart Order Process Type:</label>
                            </div>
                            <div class="text-center"> {{_sharedMethodService.getProcessType()}}
                            </div>
                        </div>
                        <div *ngFor="let item of _enrollSession.data.RetailOrderSummary.SmartOrders; let i = index">
                            <div class="display-flex">
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text cursor-pointer text-center descriptionName" (click)="productInfo(item)">{{item.Description}}</div>
                                <div *ngIf="currentRouterPath !='/Confirmation' && (item.ParentId != item.ItemCode && item.ParentId)" id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                fxLayout="column" fxLayoutAlign="center start" style="margin-left: auto;">
                                <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                    <div class="input-remove" fxLayoutAlign="center center">
                                        <span>{{item.Quantity}}</span>
                                    </div>
                                </div>
                            </div>
                            </div>
                                <div style="display: flex;">
                                <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId" src="{{item?.SmallImage}}"
                                    onerror="this.src='../../../assets/img/noimage.png'" loading="lazy"
                                    id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt="" class="order-summary-img img-box"
                                    style="margin-left: 0%;" [ngClass]="{'cursor-pointer': (FromShareLinkCart === 'true')}" (click)="productInfo(item)">
                                <div class="w-layout-grid  order-summary-grid-smart" style="margin-left: 5%;">
                                    <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                        id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                        class="order-summary-product-text cursor-pointer"></div>
                                    <div *ngIf="currentRouterPath =='/Confirmation'"
                                        id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column" fxLayoutAlign="start center">
                                        <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                            <div class="input-remove" fxLayoutAlign="center center">
                                                <span>{{item.Quantity}}</span>
                                            </div>
                                        </div>
                                    </div>
                                        <div class="quantityMeasureBox" id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                        fxLayoutAlign="start center"
                                        *ngIf="currentRouterPath !='/Confirmation' && item.ItemCode != '7800GC'">
                                        <div class="input-group" fxLayout="row" fxLayoutAlign="center baseline"
                                            *ngIf="(!item.ParentId)">
                                            <div class="input-group-btn" *ngIf="item.Quantity > 1">
                                                <button mat-button-icon type="button" fxLayoutAlign="center center">
                                                    <mat-icon
                                                        (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,3)">
                                                        remove</mat-icon>
                                                </button>
                                            </div>
    
                                            <div *ngIf="item.Quantity == 1" class="input-control" fxLayoutAlign="center center">
                                                <span fxLayoutAlign="center center" class="remove-item" (click)="removedOrder(i, item.Quantity, item.ItemCode, 'smartOrder')">
                                                    <img src="../../../assets/icons/bin.png" alt="" class="cart-w-16"></span>
                                            </div>
                                             
                                            <div class="input-control" fxLayoutAlign="center center">
                                                <span>{{item.Quantity}}</span>
                                            </div>
                                            <div class="input-group-btn">
                                                <button mat-button-icon type="button" fxLayoutAlign="center center">
                                                    <mat-icon class="line-h-0"
                                                        (click)="_sharedMethodService.ContinueRetailOrder(item,1,3);" >add
                                                    </mat-icon>
                                                </button>
                                            </div>
                                        </div>
    
                                        <div class="input-group" fxLayout="row" fxLayoutAlign="center baseline"
                                            *ngIf="item.ParentId == item.ItemCode && item.ParentId">
                                            <div class="input-control" fxLayoutAlign="center center">
                                                <span fxLayoutAlign="center center" class="remove-item" (click)="removedOrder(i, item.Quantity, item.ItemCode, 'smartOrder')">
                                                    <img src="../../../assets/icons/bin.png" alt="" class="cart-w-20"></span>
                                            </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <div class="input-control" fxLayoutAlign="center center">
                                                <span>{{item.Quantity}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a" class="order-summary-product-price"
                                        *ngIf="(item.ParentId == item.ItemCode && item.ParentId)">${{item.Price
                                        |number :
                                        '1.2-2'}} USD</div>
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price text-size"
                                    *ngIf="item.ParentId == item.ItemCode && item.ParentId">
                                    *Customizable packs have to be added individually</div>
                
                                </div>
                            </div>
                        </div>
                        <!-- Colorado Retail Delivery Fees -->
                        <div class="w-layout-grid order-summary-grid"
                            *ngIf="_enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 && _enrollSession.data.RetailOrderSummary?.shippingFormData?.State == 'CO'"
                            style="margin-bottom: 10px;">
                            <div fxLayoutAlign="center center">
                                <img src="../../../assets/images/image/retail-delivery-fees.png" loading="lazy"
                                    id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                    class="order-summary-img img-box delivery-fee-image">
                            </div>
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text">{{
                                _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.description
                                }}</div>

                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text" fxLayoutAlign="center start"> {{
                                _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.quantity
                                }} </div>

                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price">${{
                                _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.price
                                |number : '1.2-2'}} USD</div>

                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price cursor-pointer">
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-divider"></div>
                    <div class="order-summary-totals-div-block" *ngIf="_calculateAutoOrder.getIsCallCalculateOrder()">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">SubTotal</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Tax</div>
                            <div class="order-summary-text">Smart Order Total</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.SubTotal |number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.ShippingTotal |number
                                    : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Tax|number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Total|number :
                                    '1.2-2'}} USD</span>
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-totals-div-block" *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder()">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">SubTotal</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Tax</div>
                            <div class="order-summary-text">Smart Order Total</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.SubTotal |number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.ShippingTotal |number
                                    : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Tax|number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Total|number :
                                    '1.2-2'}} USD</span>
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-divider"></div>
                    <p class="m-custom-20">Please note if this is a backup order set to process this month, it will not
                        process as the order you place today will push your smart order to the following month</p>
                </div>
                <div class="padding-warning-box" *ngIf="checkwarningStatement()">
                    <div class="warning-text-for-CA">
                        <img src="../../../assets/icons/warning 1.png" width="15">
                        <span class="f-size-9"><b>WARNING:</b> Reproductive harm - p65warnings.ca.gov</span>
                    </div>
                    <div class="warning-bottom-text">This warning is required by the State of California to meet Proposition 65 requirements</div>
                </div>
                </div>
            </div>
            <div class="order-summary-divider"></div>
            <div [ngClass]="{'order-summary-continue-button-div': currentRouterPath != '/Shipping' &&  currentRouterPath != '/Checkout', 'order-summary-continue-button-div-center' : currentRouterPath == '/Shipping' ||  currentRouterPath == '/Checkout' }"
                        *ngIf="currentRouterPath != '/Confirmation'">
                <a *ngIf="currentRouterPath != '/Subscribe' && currentRouterPath != '/Shipping' &&  currentRouterPath != '/Checkout'"
                        data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                        class="button continue-button w-button hover-white"
                        (click)="onClickChange();_sharedMethodService.closeCart()">Checkout</a>
                <a *ngIf="currentRouterPath === '/Subscribe'"
                        data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                        class="button continue-button w-button hover-white"
                        (click)="subscribeDialog();_sharedMethodService.closeCart()">Checkout</a>
                <a *ngIf="currentRouterPath == '/Shipping'" data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                    class="button continue-button continue-button-mobile w-button" (click)="onClickChange();">Continue
                    To
                    Payment</a>
                <a *ngIf="currentRouterPath == '/Checkout'" data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                    class="button continue-button continue-button-mobile w-button" [class.disabled]="_sharedMethodService.submissionInProgress ? true: false"
                    (click)="onClickChange(true);">Review your Order</a>
            </div>
        </div>
    </ng-container>
    <!-- Not for Mobile Devices -->
    <mat-sidenav *ngIf="!_enrollSession.mobileViewMethod() && _sharedMethodService.isOpen">
        <ng-container *ngIf="!_enrollSession.mobileViewMethod()">
            <div class="order-summary-div">
                <div class="cartClose"><span class="remove-item c-green" (click)="_sharedMethodService.closeCart(sideNav)">Close</span>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-around start">
                    <h1 *ngIf="_enrollSession.data.IsLoggedIn && false" class="order-summary-h1 f-s-18-custom">Hi
                        {{_enrollSession.data.LoggedInUserData?.FirstName}} | <a
                            class="cursor-pointer f-s-16-custom myaccountText" (click)="redirectToBackoffice()">My
                            Account</a> | <span class="cursor-pointer f-s-16-custom" (click)="logout()">Logout</span>
                    </h1>
                    <div class="w-100-div" *ngIf="_enrollSession.data.IsLoggedIn && false">
                        <div class="w-100">
                            <div class=" order-summary-h1">LOGOUT</div>
                        </div>
                    </div>
                </div>
                <div class="summaryCart">
                    <ng-container
                        *ngIf="(!_enrollSession.data.RetailOrderSummary || (!_enrollSession.data.RetailOrderSummary.Enrollpack || _enrollSession.data.RetailOrderSummary.Enrollpack.length == 0) && (!_enrollSession.data.RetailOrderSummary.InitialOrders || _enrollSession.data.RetailOrderSummary.InitialOrders.length == 0) && (!_enrollSession.data.RetailOrderSummary.SmartOrders || _enrollSession.data.RetailOrderSummary.SmartOrders.length == 0))">
                        <div class="order-summary-totals-div-block">
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text">SubTotal</div>
                                <div class="order-summary-text">Shipping</div>
                                <div class="order-summary-text">Tax</div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                    QV
                                </div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                    BV
                                </div>
                            </div>
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text">---</div>
                                <div class="order-summary-text">---</div>
                                <div class="order-summary-text">---</div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">---
                                </div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">---
                                </div>
                            </div>
                        </div>
                        <div class="order-summary-divider"></div>
                        <div class="order-summary-totals-div-block">
                            <div class="order-summary-totals-text-div-block order-summary-labels">
                                <div class="order-summary-text order-summary-text-total">Your Total:</div>
                            </div>
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text order-summary-text-total">---</div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="order-summary-product-div-block"
                        *ngIf="_enrollSession.data.RetailOrderSummary && ((_enrollSession.data.RetailOrderSummary.Enrollpack && _enrollSession.data.RetailOrderSummary.Enrollpack.length > 0) || (_enrollSession.data.RetailOrderSummary.InitialOrders && _enrollSession.data.RetailOrderSummary.InitialOrders.length > 0) || (_enrollSession.data.RetailOrderSummary.SmartOrders && _enrollSession.data.RetailOrderSummary.SmartOrders.length > 0))">
                        <div class="w-100-div"
                            *ngIf="_enrollSession.data.RetailOrderSummary && (_enrollSession.data.RetailOrderSummary?.Enrollpack && _enrollSession.data.RetailOrderSummary?.Enrollpack.length > 0)">
                            <div class="w-100">
                                <div class=" order-summary-h1" [textContent]="packSectionTitle">
                                </div>
                            </div>
                        </div>
                        <div
                            *ngFor="let item of _enrollSession.data.RetailOrderSummary.Enrollpack | sortBy: 'asc':'-Price'; let i = index">
                            <div class="w-layout-grid order-summary-grid-smart">
                                <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                    id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text cursor-pointer"></div>
                                <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId"
                                    src="{{item.SmallImage? item.SmallImage : '../../../assets/img/noimage.png'}}"
                                    loading="lazy" id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                    [ngClass]="{'gift_img': item.ItemCode=='7800GC','cursor-pointer': (FromShareLinkCart === 'true')}" class="order-summary-img img-box" (click)="productInfo(item)">
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text" [ngClass]="{'cursor-pointer': (FromShareLinkCart === 'true')}" (click)="productInfo(item)">{{item.Description}}</div>
                                    <div class="quantityMeasureBox" id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                    fxLayoutAlign="start center"
                                    *ngIf="currentRouterPath !='/Confirmation' && item.ItemCode != '7800GC'">
                                    <div class="input-group" fxLayout="row" fxLayoutAlign="center baseline"
                                        *ngIf="(!item.ParentId)">
                                        <div class="input-group-btn tooltip_summary" *ngIf="item.Quantity > 1">
                                            <button mat-button-icon type="button" fxLayoutAlign="center center">
                                                <mat-icon
                                                    (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,1)">
                                                    remove</mat-icon>
                                            </button>
                                            <span class="tooltiptext_summary">Decrease</span>
                                        </div>

                                        <div *ngIf="item.Quantity == 1 && i == 0" class="input-control" fxLayoutAlign="center center">
                                            <span fxLayoutAlign="center center" class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'transformation')">
                                                <img src="../../../assets/icons/bin.png" alt="" class="cart-w-16"></span>
                                        </div>
                                         
                                        <div class="input-control" fxLayoutAlign="center center">
                                            <span>{{item.Quantity}}</span>
                                        </div>
                                        <div class="input-group-btn tooltip_summary">
                                            <button mat-button-icon type="button" fxLayoutAlign="center center">
                                                <mat-icon class="line-h-0"
                                                    (click)="_sharedMethodService.ContinueRetailOrder(item,1,1);" >add
                                                </mat-icon>
                                            </button>
                                            <span class="tooltiptext_summary">Increase</span>
                                        </div>
                                    </div>

                                    <div class="input-group" fxLayout="row" fxLayoutAlign="center baseline"
                                        *ngIf="item.ParentId == item.ItemCode && item.ParentId">
                                        <div *ngIf="i == 0" class="input-control" fxLayoutAlign="center center">
                                            <span fxLayoutAlign="center center" class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'transformation')">
                                                <img src="../../../assets/icons/bin.png" alt="" class="cart-w-16"></span>
                                        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div class="input-control" fxLayoutAlign="center center">
                                            <span>{{item.Quantity}}</span>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="currentRouterPath !='/Confirmation' && ( item.ItemCode=='7800GC' || (item.ParentId != item.ItemCode && item.ParentId))"
                                        id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column" fxLayoutAlign="start center">
                                        <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                            <div class="input-remove" fxLayoutAlign="center center">
                                                <span>{{item.Quantity}}</span>
                                            </div>
                                        </div>
                                    </div>
                                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        class="order-summary-product-price"
                                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                                        ${{item.Price |number : '1.2-2'}}
                                        USD</div>
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        class="order-summary-product-price"
                                        *ngIf="(_enrollSession.data.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType!=10)">
                                        ${{item.Other1Price |number : '1.2-2'}} USD</div>
                                </ng-container>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    *ngIf="_sharedMethodService.validCustomerType() && (item.ParentId == item.ItemCode || !item.ParentId) && item.ItemCode!='11050-FREE' && item.ItemCode!='11050' && item.ItemCode!='11020'"
                                    class="order-summary-product-price">{{item.BV || 0}} BV</div>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    *ngIf="_sharedMethodService.validCustomerType() && (item.ParentId == item.ItemCode || !item.ParentId) &&  ( item.ItemCode=='11050-FREE' || item.ItemCode=='11050' || item.ItemCode=='11020')"
                                    class="order-summary-product-price">{{item.BV || 0}} QV</div>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price text-size"
                                    *ngIf="item.ParentId == item.ItemCode && item.ParentId">
                                    *Customizable packs have to be added individually</div>
                            </div>
                        </div>



                        <!-- Colorado Retail Delivery Fees -->
                        <div class="w-layout-grid order-summary-grid"
                            *ngIf="_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.price"
                            style="margin-bottom: 10px;">
                            <div fxLayoutAlign="center center">
                                <img src="../../../assets/images/image/retail-delivery-fees.png" loading="lazy"
                                    id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                    class="order-summary-img img-box delivery-fee-image">
                            </div>
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text">
                                {{
                                _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees.description
                                }}</div>

                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text" fxLayoutAlign="center start"> {{
                                _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees.quantity
                                }} </div>

                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price">
                                ${{
                                _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees.price
                                |number : '1.2-2'}} USD</div>

                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price cursor-pointer">
                            </div>
                        </div>

                    </div>
                    <div class="order-summary-divider"
                        *ngIf="_enrollSession.data.RetailOrderSummary && (_enrollSession.data.RetailOrderSummary?.Enrollpack && _enrollSession.data.RetailOrderSummary?.Enrollpack.length > 0)">
                    </div>
                    <div class="order-summary-product-div-block"
                        *ngIf="_enrollSession.data.RetailOrderSummary?.InitialOrders && _enrollSession.data.RetailOrderSummary?.InitialOrders.length > 0">
                        <div class="w-100-div">
                            <div class="w-100">
                                <div class=" order-summary-h1">Today&#x27;s Order</div>
                            </div>
                        </div>
                        <div *ngFor="let item of _enrollSession.data.RetailOrderSummary.InitialOrders; let i = index">
                            <div class="w-layout-grid order-summary-grid">
                                <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                    id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text cursor-pointer"></div>
                                <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId"
                                    src="{{item.SmallImage? item.SmallImage : '../../../assets/img/noimage.png'}}"
                                    loading="lazy" id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                    class="order-summary-img img-box" [ngClass]="{'cursor-pointer': (FromShareLinkCart === 'true')}" (click)="productInfo(item)">
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text" [ngClass]="{'cursor-pointer': (FromShareLinkCart === 'true')}" (click)="productInfo(item)">{{item.Description}}</div>

                                <div *ngIf="currentRouterPath =='/Confirmation' || item.ItemCode == '7800GC'"
                                        id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column" fxLayoutAlign="start center">
                                        <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                            <div class="input-remove" fxLayoutAlign="center center">
                                                <span>{{item.Quantity}}</span>
                                            </div>
                                        </div>
                                    </div>
                                <div class="quantityMeasureBox" id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                    fxLayoutAlign="start center"
                                    *ngIf="currentRouterPath !='/Confirmation' && item.ItemCode != '7800GC'">
                                    <div class="input-group" fxLayout="row" fxLayoutAlign="center baseline"
                                        *ngIf="(!item.ParentId)">
                                        <div class="input-group-btn tooltip_summary" *ngIf="item.Quantity > 1">
                                            <button mat-button-icon type="button" fxLayoutAlign="center center">
                                                <mat-icon
                                                    (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,2)">
                                                    remove</mat-icon>
                                            </button>
                                            <span class="tooltiptext_summary">Decrease</span>
                                        </div>

                                        <div *ngIf="item.Quantity == 1" class="input-control" fxLayoutAlign="center center">
                                            <span fxLayoutAlign="center center" class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'todayOrder')">
                                                <img src="../../../assets/icons/bin.png" alt="" class="cart-w-16"></span>
                                        </div>
                                         
                                        <div class="input-control" fxLayoutAlign="center center">
                                            <span>{{item.Quantity}}</span>
                                        </div>
                                        <div class="input-group-btn tooltip_summary">
                                            <button mat-button-icon type="button" fxLayoutAlign="center center">
                                                <mat-icon class="line-h-0"
                                                    (click)="_sharedMethodService.ContinueRetailOrder(item,1,2);" >add
                                                </mat-icon>
                                            </button>
                                            <span class="tooltiptext_summary">Increase</span>
                                        </div>
                                    </div>

                                    <div class="input-group" fxLayout="row" fxLayoutAlign="center baseline"
                                        *ngIf="item.ParentId == item.ItemCode && item.ParentId">
                                        <div class="input-control" fxLayoutAlign="center center">
                                            <span fxLayoutAlign="center center" class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'todayOrder')">
                                                <img src="../../../assets/icons/bin.png" alt="" class="cart-w-20"></span>
                                        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div class="input-control" fxLayoutAlign="center center">
                                            <span>{{item.Quantity}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="currentRouterPath !='/Confirmation' && ((item.ParentId != item.ItemCode && item.ParentId))"
                                    id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column" fxLayoutAlign="start center">
                                    <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                        <div class="input-remove" fxLayoutAlign="center center">
                                            <span>{{item.Quantity}}</span>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        class="order-summary-product-price"
                                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                                        ${{item?.Price |number : '1.2-2'}}
                                        USD</div>
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                         class="order-summary-product-price"
                                         *ngIf="(_enrollSession.data.RetailOrderProductDetail) && ((_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10))">
                                        ${{getItemPrice(item) |number : '1.2-2' }}
                                        USD
                                    </div>
                                </ng-container>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    *ngIf="_sharedMethodService.validCustomerType() && (item.ParentId == item.ItemCode || !item.ParentId)"
                                    class="order-summary-product-price">{{item.BV || 0}} BV</div>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price text-size"
                                    *ngIf="item.ParentId == item.ItemCode && item.ParentId">
                                    *Customizable packs have to be added individually</div>
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-divider"></div>
                    <div
                        *ngIf="_enrollSession.data.RetailOrderSummary?.Enrollpack && _enrollSession.data.RetailOrderSummary?.Enrollpack.length > 0 || _enrollSession.data.RetailOrderSummary?.InitialOrders && _enrollSession.data.RetailOrderSummary?.InitialOrders.length > 0  || _enrollSession.data.RetailOrderSummary?.AddOnOrders && _enrollSession.data.RetailOrderSummary?.AddOnOrders.length > 0">
                        <div class="order-summary-totals-div-block"
                            *ngIf="_calculateAutoOrder.getIsCallCalculateOrder()">
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text">SubTotal</div>
                                <div class="order-summary-text">Shipping</div>
                                <div class="order-summary-text">Tax</div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                    QV
                                </div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                    BV
                                </div>
                                <div class="order-summary-text">Order Total</div>
                                <div class="order-summary-text" *ngIf="_calculateRetailOrderService.isSelected">Reward Points Redeemed</div>
                                <div class="order-summary-text" *ngIf="_calculateRetailOrderService.isSelected">Credit Card Payment Due</div>
                                <div class="order-summary-text italic-text" id="totalsaving">Loyalty Discount Savings</div>
                            </div>
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal |number
                                        :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.ShippingTotal
                                        |number :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Tax|number :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                    <span>{{_enrollSession.data?.RetailOrderSummary.InitialOrderDetails?.BV}} </span>
                                </div>
                                <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                    <span>{{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.CV}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total|number :
                                        '1.2-2'}}
                                        USD</span>
                                </div>
                                <div class="order-summary-text t-a-r " *ngIf="_calculateRetailOrderService.isSelected">
                                    <span>-(${{_calculateRetailOrderService.DiscountValue |number : '1.2-2'}})</span>
                                </div>
                                <div class="order-summary-text t-a-r " *ngIf="_calculateRetailOrderService.isSelected">
                                    <span>${{_calculateRetailOrderService.totalRemainingBalance |number : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r" >
                                    <span id="totalsaving"> <b> ${{ getDiscountTotal() | number : '1.2-2'}} USD</b></span>
                                </div>
                            </div>
                        </div>

                        <div class="order-summary-divider"></div>
                        <div class="order-summary-totals-div-block"
                            *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder()">
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text">SubTotal</div>
                                <div class="order-summary-text">Shipping</div>
                                <div class="order-summary-text">Tax</div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                    QV
                                </div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                    BV
                                </div>
                                <div class="order-summary-text">Your Total</div>
                                <div class="order-summary-text" *ngIf="_calculateRetailOrderService.isSelected">Reward Points Redeemed</div>
                                <div class="order-summary-text" *ngIf="_calculateRetailOrderService.isSelected">Credit Card Payment Due</div>
                                <div class="order-summary-text italic-text" id="totalsaving"><b>Loyalty Discount Savings</b> </div>
                            </div>
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal |number
                                        :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.ShippingTotal
                                        |number :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Tax|number :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                    <span>{{_enrollSession.data?.RetailOrderSummary.InitialOrderDetails?.BV}} </span>
                                </div>
                                <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                    <span>{{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.CV}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total|number :
                                        '1.2-2'}}
                                        USD</span>
                                </div>
                                <div class="order-summary-text t-a-r " *ngIf="_calculateRetailOrderService.isSelected">
                                    <span>-(${{_calculateRetailOrderService.DiscountValue |number : '1.2-2'}})</span>
                                </div>
                                <div class="order-summary-text t-a-r " *ngIf="_calculateRetailOrderService.isSelected">
                                    <span>${{_calculateRetailOrderService.totalRemainingBalance |number : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r" >
                                    <span id="totalsaving"> <b> ${{ getDiscountTotal() | number : '1.2-2'}} USD</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="_enrollSession.data.RetailOrderSummary?.SmartOrders && _enrollSession.data.RetailOrderSummary?.SmartOrders.length>0">
                        <div class="order-summary-divider"></div>
                        <div class="order-summary-product-div-block">
                            <div class="w-100-div">
                                <div class="w-100 ">
                                    <div class="order-summary-h1" *ngIf="isLoginCartButton()">Your Current Smart Order Subscription
                                    </div>
                                    <div class="order-summary-h1" *ngIf="!isLoginCartButton()">Smart Order Subscription
                                    </div>
                                </div>
                            </div>
                            <div class="smartOrderDateBoxCart " fxLayout="column">
                                <div class="p-r-10 text-center">
                                    <label>Your Smart Order Date:</label>
                                </div>
                                <div class="text-center">
                                    <div>{{_enrollSession.data.smartOrderDate ? _enrollSession.data.smartOrderDate : (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 ? smartOrderDateForLifestyle : '--/--/----')}}</div>
                                </div>
                            </div>
                                <div class="text-center">
                                    <label>Your Smart Order Process Type:</label>
                                </div>
                                <div class="text-center">
                                    {{ _sharedMethodService.getProcessType() }}
                                </div>
                            <div *ngFor="let item of _enrollSession.data.RetailOrderSummary.SmartOrders; let i = index">
                                <div class="w-layout-grid  order-summary-grid-smart">
                                    <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                        id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                        class="order-summary-product-text cursor-pointer"></div>
                                    <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId"
                                        src="{{item?.SmallImage}}" onerror="this.src='../../../assets/img/noimage.png'"
                                        loading="lazy" id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                        class="order-summary-img img-box" [ngClass]="{'cursor-pointer': (FromShareLinkCart === 'true')}" (click)="productInfo(item)">
                                    <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                        class="order-summary-product-text" [ngClass]="{'cursor-pointer': (FromShareLinkCart === 'true')}" (click)="productInfo(item)">{{item.Description}}</div>
                                    <div *ngIf="currentRouterPath =='/Confirmation'"
                                        id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column" fxLayoutAlign="start center">
                                        <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                            <div class="input-remove" fxLayoutAlign="center center">
                                                <span>{{item.Quantity}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="quantityMeasureBox" id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                        fxLayoutAlign="start center"
                                        *ngIf="currentRouterPath !='/Confirmation' && item.ItemCode != '7800GC'">
                                        <div class="input-group" fxLayout="row" fxLayoutAlign="baseline center"
                                            *ngIf="(!item.ParentId)">
                                            <div class="input-group-btn tooltip_summary" *ngIf="item.Quantity > 1">
                                                <button mat-button-icon type="button" fxLayoutAlign="center center">
                                                    <mat-icon
                                                        (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,3)">
                                                        remove</mat-icon>
                                                </button>
                                                <span class="tooltiptext_summary">Decrease</span>
                                            </div>
    
                                            <div *ngIf="item.Quantity == 1" class="input-control" fxLayoutAlign="center center">
                                                <span fxLayoutAlign="center center" class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'smartOrder')">
                                                    <img src="../../../assets/icons/bin.png" alt="" class="cart-w-16"></span>
                                            </div>
                                             
                                            <div class="input-control" fxLayoutAlign="center center">
                                                <span>{{item.Quantity}}</span>
                                            </div>
                                            <div class="input-group-btn tooltip_summary">
                                                <button mat-button-icon type="button" fxLayoutAlign="center center">
                                                    <mat-icon class="line-h-0"
                                                        (click)="_sharedMethodService.ContinueRetailOrder(item,1,3);" >add
                                                    </mat-icon>
                                                </button>
                                                <span class="tooltiptext_summary">Increase</span>
                                            </div>
                                        </div>
    
                                        <div class="input-group" fxLayout="row" fxLayoutAlign="baseline center"
                                            *ngIf="item.ParentId == item.ItemCode && item.ParentId">
                                            <div class="input-control" fxLayoutAlign="center center">
                                                <span fxLayoutAlign="center center" class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'smartOrder')">
                                                    <img src="../../../assets/icons/bin.png" alt="" class="cart-w-20"></span>
                                            </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <div class="input-control" fxLayoutAlign="center center">
                                                <span>{{item.Quantity}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="currentRouterPath !='/Confirmation' && ((item.ParentId != item.ItemCode && item.ParentId))"
                                        id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column" fxLayoutAlign="start center">
                                        <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                            <div class="input-remove" fxLayoutAlign="center center">
                                                <span>{{item.Quantity}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                            class="order-summary-product-price">${{item.Price |number :
                                            '1.2-2'}} USD</div>
                                    </ng-container>

                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        class="order-summary-product-price text-size"
                                        *ngIf="item.ParentId == item.ItemCode && item.ParentId">
                                        *Customizable packs have to be added individually</div>
                                    </div>
                            </div>

                            <!-- Colorado Retail Delivery Fees -->
                            <div class="w-layout-grid order-summary-grid"
                                *ngIf="_enrollSession.data.RetailOrderSummary?.SmartOrders.length > 0 && _enrollSession.data.RetailOrderSummary?.shippingFormData?.State == 'CO'"
                                style="margin-bottom: 10px;">
                                <div fxLayoutAlign="center center">
                                    <img src="../../../assets/images/image/retail-delivery-fees.png" loading="lazy"
                                        id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                        class="order-summary-img img-box delivery-fee-image">
                                </div>
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text">{{
                                    _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.description
                                    }}</div>

                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text" fxLayoutAlign="center start"> {{
                                    _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.quantity
                                    }} </div>

                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price">${{
                                    _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.price
                                    |number : '1.2-2'}} USD</div>

                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price cursor-pointer">
                                </div>
                            </div>


                        </div>
                        <div class="order-summary-divider"></div>
                        <div class="order-summary-totals-div-block"
                            *ngIf="_calculateAutoOrder.getIsCallCalculateOrder()">
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text">SubTotal</div>
                                <div class="order-summary-text">Shipping</div>
                                <div class="order-summary-text">Tax</div>
                                <div class="order-summary-text">Smart Order Total</div>
                            </div>
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.SubTotal |number
                                        :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.ShippingTotal
                                        |number
                                        :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Tax|number :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Total|number :
                                        '1.2-2'}}
                                        USD</span>
                                </div>
                            </div>
                        </div>
                        <div class="order-summary-totals-div-block"
                            *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder()">
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text">SubTotal</div>
                                <div class="order-summary-text">Shipping</div>
                                <div class="order-summary-text">Tax</div>
                                <div class="order-summary-text">Smart Order Total</div>
                            </div>
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.SubTotal |number
                                        :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.ShippingTotal
                                        |number
                                        :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Tax|number :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Total|number :
                                        '1.2-2'}}
                                        USD</span>
                                </div>
                            </div>
                        </div>
                        <div class="order-summary-divider"></div>
                        <p class="m-custom-20">Please note if this is a backup order set to process this month, it will
                            not
                            process as the order you place today will push your smart order to the following month</p>
                    </div>
                    <div class="padding-warning-box" *ngIf="checkwarningStatement()">
                        <div class="warning-text-for-CA">
                            <img src="../../../assets/icons/warning 1.png" width="15">
                            <span class="f-size-warning"><b>WARNING:</b> Reproductive harm - p65warnings.ca.gov</span>
                        </div>
                        <div class="warning-bottom-text">This warning is required by the State of California to meet Proposition 65 requirements</div>
                    </div>
                </div>
                <div [ngClass]="{'order-summary-continue-button-div': currentRouterPath != '/Shipping' &&  currentRouterPath != '/Checkout', 'order-summary-continue-button-div-center' : currentRouterPath == '/Shipping' ||  currentRouterPath == '/Checkout' }"
                    *ngIf="currentRouterPath != '/Confirmation'">
                    <a *ngIf="currentRouterPath != '/Subscribe' && currentRouterPath != '/Shipping' &&  currentRouterPath != '/Checkout'"
                        data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                        class="button continue-button w-button hover-white"
                        (click)="onClickChange();_sharedMethodService.closeCart()">Checkout</a>
                    <a *ngIf="currentRouterPath === '/Subscribe'"
                        data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                        class="button continue-button w-button hover-white"
                        (click)="subscribeDialog();_sharedMethodService.closeCart()">Checkout</a>
                    <a *ngIf="currentRouterPath == '/Shipping'" data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                        class="button continue-button continue-button-shipping w-button hover-white"
                        (click)="onClickChange();_sharedMethodService.closeCart()">Continue
                        To Payment</a>
                    <a *ngIf="currentRouterPath == '/Checkout'" data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                        class="button continue-button continue-button-shipping w-button hover-white"  [class.disabled]="_sharedMethodService.submissionInProgress ? true: false"
                        (click)="onClickChange(true); _sharedMethodService.closeCart()">Review your Order</a>
                </div>
            </div>
        </ng-container>
    </mat-sidenav>
</section>
<section *ngIf="siteType=='ultlifestyle'" class="z-indexxx" [ngClass]="{'withoutSharelinkCart':(currentPage() && !_sharedMethodService.giftcardNameMethod()), 'sharelinkCart': (currentPage() && (router.url.includes('homepage') || router.url.includes('Ultlifestyle')) && _sharedMethodService.giftcardNameMethod())}">
    <ng-container *ngIf="_enrollSession.SummaryCartMobOnly && currentPage()">
        <div class="order-summary-div-mobile order-summary-mobile">
            <div class="w-100-div" fxLayoutAlign.xs="end end" style="padding: 12px;">
                <div><span class="c-green f-s-14 f-w-600" (click)="closeMobileSummaryCart()">CLOSE</span>
                </div>
            </div>
            <div class="divContainermobile" [ngClass]="{'divContainermobileShareLink': (_sharedMethodService.giftcardNameMethod() && (router.url.includes('homepage') || router.url.includes('Ultlifestyle')))}">
                <ng-container
                    *ngIf="currentRouterPath == '/OrderReview' && (!_enrollSession.data.RetailOrderSummary || (!_enrollSession.data.RetailOrderSummary.Enrollpack || _enrollSession.data.RetailOrderSummary.Enrollpack.length == 0) && (!_enrollSession.data.RetailOrderSummary.InitialOrders || _enrollSession.data.RetailOrderSummary.InitialOrders.length == 0) && (!_enrollSession.data.RetailOrderSummary.SmartOrders || _enrollSession.data.RetailOrderSummary.SmartOrders.length == 0))">
                    <div class="order-summary-divider"></div>
                    <div class="order-summary-totals-div-block">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">SubTotal</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Tax</div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                QV</div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                BV</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">---</div>
                            <div class="order-summary-text">---</div>
                            <div class="order-summary-text">---</div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">---
                            </div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">---
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-divider"></div>
                    <div class="order-summary-totals-div-block">
                        <div class="order-summary-totals-text-div-block order-summary-labels">
                            <div class="order-summary-text order-summary-text-total">Your Total:</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text order-summary-text-total">---</div>
                        </div>
                    </div>
                </ng-container>
                <div class="order-summary-product-div-block"
                    *ngIf="_enrollSession.data.RetailOrderSummary && ((_enrollSession.data.RetailOrderSummary.Enrollpack && _enrollSession.data.RetailOrderSummary.Enrollpack.length > 0) || (_enrollSession.data.RetailOrderSummary.InitialOrders && _enrollSession.data.RetailOrderSummary.InitialOrders.length > 0) || (_enrollSession.data.RetailOrderSummary.SmartOrders && _enrollSession.data.RetailOrderSummary.SmartOrders.length > 0))">
                    <div class="w-100-div">
                        <div class="w-100 ">
                            <div class=" order-summary-h1">Today's Order</div>
                        </div>
                    </div>
                    <div
                        *ngFor="let item of _enrollSession.data.RetailOrderSummary.Enrollpack | sortBy: 'asc':'-Price'; let i = index">
                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text cursor-pointer text-center descriptionName">
                                {{item.Description}}</div>
                        <div style="display: flex;">
                            <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId" [src]="item.SmallImage"
                            onError="this.src='assets/img/noimage.png';" loading="lazy"
                            id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt="" class="order-summary-img img-box">
                                <div>
                                    <div class="w-layout-grid order-summary-grid-smart" id="order-summary-smart">
                                        <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                            id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                            class="order-summary-product-text cursor-pointer"></div>
                                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" class="order-summary-product-text"
                                            fxLayoutAlign="center start" *ngIf="currentRouterPath =='/Confirmation'">{{item.Quantity}}</div>
                                        <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a" class="order-summary-product-price"
                                                *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                                                ${{item?.Price |number : '1.2-2'}} USD</div>
                                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a" class="order-summary-product-price"
                                                *ngIf="(_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10)">
                                                ${{getItemPrice(item) | number : '1.2-2' }} USD
                                            </div>
                                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                                *ngIf="_sharedMethodService.validCustomerType() && (item.ParentId == item.ItemCode || !item.ParentId) && item.ItemCode!='11050-FREE' && item.ItemCode!='11050' && item.ItemCode!='11020'"
                                                class="order-summary-product-price">{{item.BV || 0}} BV</div>
                                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                                *ngIf="_sharedMethodService.validCustomerType() && (item.ParentId == item.ItemCode || !item.ParentId) &&  ( item.ItemCode=='11050-FREE' || item.ItemCode=='11050' || item.ItemCode=='11020')"
                                                class="order-summary-product-price">{{item.BV || 0}} QV</div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    fxLayoutAlign="start center" *ngIf="currentRouterPath !='/Confirmation'" style="margin-left: auto;"> 
                                    <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                        <div class="input-remove" fxLayoutAlign="center center">
                                            <span>{{item.Quantity}}</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <br>
                    </div>
                    <!-- Colorado Retail Delivery Fees -->
                    <div class="w-layout-grid order-summary-grid"
                        *ngIf="_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.price && _enrollSession.data.RetailOrderSummary.Enrollpack.length > 0"
                        style="margin-bottom: 10px;">
                        <div fxLayoutAlign="center center">
                            <img src="../../../assets/images/image/retail-delivery-fees.png" loading="lazy"
                                id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                class="order-summary-img img-box delivery-fee-image">
                        </div>
                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                            class="order-summary-product-text">{{
                            _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees.description
                            }}</div>

                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                            class="order-summary-product-text" fxLayoutAlign="center start"> {{
                            _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees.quantity
                            }} </div>

                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                            class="order-summary-product-price">${{
                            _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees.price
                            |number : '1.2-2'}} USD</div>

                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                            class="order-summary-product-price cursor-pointer">
                        </div>
                    </div>

                    <div *ngFor="let item of _enrollSession.data.RetailOrderSummary.InitialOrders; let i = index">
                        <div class="display-flex">
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                            class="order-summary-product-text cursor-pointer text-center descriptionName">
                            {{item.Description}}</div>
                        <div *ngIf="item.ParentId != item.ItemCode && item.ParentId" id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                            fxLayout="column" fxLayoutAlign="start center">
                            <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                <div class="input-remove" fxLayoutAlign="center center">
                                    <span>{{item.Quantity}}</span>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div style="display: flex;">
                            <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId" [src]="item.SmallImage"
                                onError="this.src='assets/img/noimage.png';" loading="lazy"
                                id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt="" class="order-summary-img img-box">
                            <div>
                                <div class="w-layout-grid order-summary-grid-smart" id="order-summary-smart">
                                    <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                        id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                        class="order-summary-product-text cursor-pointer"></div>
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a" class="order-summary-product-price"
                                        *ngIf="(item.ParentId == item.ItemCode || !item.ParentId) && ((_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10))">${{item.Price |number :
                                        '1.2-2'}} USD</div>
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        class="order-summary-product-price"
                                        *ngIf="(_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10)">
                                        ${{getItemPrice(item) |number : '1.2-2'}}
                                        USD
                                    </div>
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        *ngIf="_sharedMethodService.validCustomerType() && (item.ParentId == item.ItemCode || !item.ParentId) && item.ItemCode!='11050-FREE' && item.ItemCode!='11050' && item.ItemCode!='11020'"
                                        class="order-summary-product-price">{{item.BV || 0}} BV</div>
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        *ngIf="_sharedMethodService.validCustomerType() && (item.ParentId == item.ItemCode || !item.ParentId) &&  ( item.ItemCode=='11050-FREE' || item.ItemCode=='11050' || item.ItemCode=='11020')"
                                        class="order-summary-product-price">{{item.BV || 0}} QV</div>
                                </div>
                            </div>
                            <div class="quantityMeasureBox" id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                    fxLayoutAlign="start center"
                                    *ngIf="(item.ParentId == item.ItemCode || !item.ParentId)">
                                    <div class="input-group" fxLayout="row" fxLayoutAlign="baseline center">
                                        <div class="input-control" fxLayoutAlign="center center">
                                            <span fxLayoutAlign="center center" class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'todayOrder')">
                                                <img src="../../../assets/icons/bin.png" alt="" class="cart-w-20"></span>
                                        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div class="input-control" fxLayoutAlign="center center">
                                            <span>{{item.Quantity}}</span>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="order-summary-divider"></div>
                <div
                    *ngIf="_enrollSession.data.RetailOrderSummary.Enrollpack && _enrollSession.data.RetailOrderSummary.Enrollpack.length > 0 || _enrollSession.data.RetailOrderSummary.InitialOrders && _enrollSession.data.RetailOrderSummary.InitialOrders.length > 0  || _enrollSession.data.RetailOrderSummary.AddOnOrders && _enrollSession.data.RetailOrderSummary.AddOnOrders.length > 0">
                    <div class="order-summary-totals-div-block" *ngIf="_calculateAutoOrder.getIsCallCalculateOrder()">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">SubTotal</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Tax</div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                QV</div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                BV</div>
                            <div class="order-summary-text">Your Total</div>
                            <div class="order-summary-text" *ngIf="_calculateRetailOrderService.isSelected">Reward Points Redeemed</div>
                            <div class="order-summary-text" *ngIf="_calculateRetailOrderService.isSelected">Credit Card Payment Due</div>
                            <div class="order-summary-text italic-text" id="totalsaving">Loyalty Discount Savings</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.SubTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ShippingTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.Tax|number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                <span>{{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.BV}}</span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                <span>{{_enrollSession.data?.RetailOrderSummary.InitialOrderDetails?.CV}} </span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.Total|number :
                                    '1.2-2'}} USD</span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_calculateRetailOrderService.isSelected">
                                <span>-(${{_calculateRetailOrderService.DiscountValue |number : '1.2-2'}})</span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_calculateRetailOrderService.isSelected">
                                <span>${{_calculateRetailOrderService.totalRemainingBalance |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r" >
                                <span id="totalsaving"> <b> ${{ getDiscountTotal() | number : '1.2-2'}} USD</b></span>
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-divider"></div>
                    <div class="order-summary-totals-div-block" *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder()">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">SubTotal</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Tax</div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                QV</div>
                            <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                BV</div>
                            <div class="order-summary-text">Your Total</div>
                            <div class="order-summary-text" *ngIf="_calculateRetailOrderService.isSelected">Reward Points Redeemed</div>
                                <div class="order-summary-text" *ngIf="_calculateRetailOrderService.isSelected">Credit Card Payment Due</div>
                                <div class="order-summary-text italic-text" id="totalsaving">Loyalty Discount Savings</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.SubTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ShippingTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.Tax|number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                <span>{{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.BV}}</span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                <span>{{_enrollSession.data?.RetailOrderSummary.InitialOrderDetails?.CV}} </span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.Total|number :
                                    '1.2-2'}} USD</span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_calculateRetailOrderService.isSelected">
                                <span>-(${{_calculateRetailOrderService.DiscountValue |number : '1.2-2'}})</span>
                            </div>
                            <div class="order-summary-text t-a-r " *ngIf="_calculateRetailOrderService.isSelected">
                                <span>${{_calculateRetailOrderService.totalRemainingBalance |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r" >
                                <span id="totalsaving"> <b> ${{ getDiscountTotal() | number : '1.2-2'}} USD</b></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="_enrollSession.data.RetailOrderSummary.SmartOrders && _enrollSession.data.RetailOrderSummary.SmartOrders.length>0">
                    <div class="order-summary-divider"></div>
                    <!--Smart Order Subscription- Months 2-3-->
                    <div class="order-summary-product-div-block">
                        <div class="w-100-div">
                            <div class="w-100">
                                <div class=" order-summary-h1">Smart Order Subscription- Months 2-3</div>
                            </div>
                        </div>
                        <div *ngFor="let item of _enrollSession.data.RetailOrderSummary.SmartOrders; let i = index">
                            <div *ngIf="item.type=='2Month'">
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text cursor-pointer text-center descriptionName">
                                    {{item.Description}}</div>
                            </div>
                            <div style="display: flex;" *ngIf="item.type=='2Month'">
                                <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId" [src]="item.SmallImage"
                                    onError="this.src='assets/img/noimage.png';" loading="lazy"
                                    id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt="" class="order-summary-img img-box">
                                <ng-container *ngIf="item.type=='2Month'">
                                    <div style="display: flex;" *ngIf="item.type=='2Month'">
                                        <div class="w-layout-grid  order-summary-grid-smart" id="order-summary-smart">
                                            <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                                id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                                class="order-summary-product-text cursor-pointer"></div>
                                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a" class="order-summary-product-price"
                                                *ngIf="(item.ParentId == item.ItemCode || !item.ParentId)">${{item.Price |number
                                                : '1.2-2'}} USD</div>
                                        </div>
                                    </div>
                                    <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" class="order-summary-product-text"
                                            fxLayoutAlign="center start" *ngIf="currentRouterPath =='/Confirmation'">{{item.Quantity}}</div>
                                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayoutAlign="start center"
                                            *ngIf="currentRouterPath !='/Confirmation'" style="margin-left: auto;">
                                            <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                                <div class="input-remove" fxLayoutAlign="center center">
                                                    <span>{{item.Quantity}}</span>
                                                </div>
                                            </div>
                                        </div>
                                </ng-container>
                            </div>
                        </div>
                        <!-- Colorado Retail Delivery Fees -->
                        <div class="w-layout-grid order-summary-grid"
                            *ngIf="_enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 && _enrollSession.data.RetailOrderSummary?.shippingFormData?.State == 'CO'"
                            style="margin-bottom: 10px;">
                            <div fxLayoutAlign="center center">
                                <img src="../../../assets/images/image/retail-delivery-fees.png" loading="lazy"
                                    id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                    class="order-summary-img img-box delivery-fee-image">
                            </div>
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text">{{
                                _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.description
                                }}</div>

                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text" fxLayoutAlign="center start"> {{
                                _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.quantity
                                }} </div>

                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price">${{
                                _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.price
                                |number : '1.2-2'}} USD</div>

                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price cursor-pointer">
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-divider"></div>
                    <div class="order-summary-totals-div-block"
                        *ngIf="_calculateAutoOrder.getIsCallCalculateOrder() && false">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">SubTotal</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Tax</div>
                            <div class="order-summary-text">Smart Order Total</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.SubTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.ShippingTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.Tax|number
                                    : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.Total|number
                                    : '1.2-2'}} USD</span>
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-totals-div-block"
                        *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder() && false">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">SubTotal</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Tax</div>
                            <div class="order-summary-text">Smart Order Total</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.SubTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.ShippingTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.Tax|number
                                    : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.Total|number
                                    : '1.2-2'}} USD</span>
                            </div>
                        </div>
                    </div>


                    <!--Smart Order Subscription- Months 4+-->
                    <div class="order-summary-divider"></div>
                    <div class="order-summary-product-div-block">
                        <div class="w-100-div">
                            <div class="w-100">
                                <div class=" order-summary-h1">Smart Order Subscription- Month 4+</div>
                            </div>
                        </div>
                        <div *ngFor="let item of _enrollSession.data.RetailOrderSummary.SmartOrders; let i = index">
                            <div *ngIf="item.type=='4Month'">
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text cursor-pointer text-center descriptionName">
                                {{item.Description}}</div>
                                </div>
                            <div style="display: flex;" *ngIf="item.type=='4Month'">
                                <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId" [src]="item.SmallImage"
                                    onError="this.src='assets/img/noimage.png';" loading="lazy"
                                    id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                    class="order-summary-img img-box">
                                    <div style="display: flex;" *ngIf="item.type=='4Month'">
                                        <div class="w-layout-grid  order-summary-grid-smart" id="order-summary-smart" *ngIf="item.type=='4Month'">
                                            <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                                id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                                class="order-summary-product-text cursor-pointer"></div>
                                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                                class="order-summary-product-price"
                                                *ngIf="(item.ParentId == item.ItemCode || !item.ParentId)">${{item.Price |number
                                                : '1.2-2'}} USD</div>
            
                                        </div>
                                    </div>
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text" fxLayoutAlign="center start"
                                    *ngIf="currentRouterPath =='/Confirmation'">{{item.Quantity}}</div>
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    fxLayoutAlign="start center" *ngIf="currentRouterPath !='/Confirmation'" style="margin-left: auto;">
                                    <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                        <div class="input-remove" fxLayoutAlign="center center">
                                            <span>{{item.Quantity}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Colorado Retail Delivery Fees -->
                        <div class="w-layout-grid order-summary-grid"
                            *ngIf="_enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 && _enrollSession.data.RetailOrderSummary?.shippingFormData?.State == 'CO'"
                            style="margin-bottom: 10px;">
                            <div fxLayoutAlign="center center">
                                <img src="../../../assets/images/image/retail-delivery-fees.png" loading="lazy"
                                    id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                    class="order-summary-img img-box delivery-fee-image">
                            </div>
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text">{{
                                _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.description
                                }}</div>

                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text" fxLayoutAlign="center start"> {{
                                _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.quantity
                                }} </div>

                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price">${{
                                _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.price
                                |number : '1.2-2'}} USD</div>

                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price cursor-pointer">
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-divider"></div>
                    <div class="order-summary-totals-div-block"
                        *ngIf="_calculateAutoOrder.getIsCallCalculateOrder() && false">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">SubTotal</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Tax</div>
                            <div class="order-summary-text">Smart Order Total</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.SubTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.ShippingTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.Tax|number
                                    : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.Total|number
                                    : '1.2-2'}} USD</span>
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-totals-div-block"
                        *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder() && false">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">SubTotal</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Tax</div>
                            <div class="order-summary-text">Smart Order Total</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.SubTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.ShippingTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.Tax|number
                                    : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.Total|number
                                    : '1.2-2'}} USD</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="padding-warning-box" *ngIf="checkwarningStatement()">
                    <div class="warning-text-for-CA">
                        <img src="../../../assets/icons/warning 1.png" width="15">
                        <span class="f-size-9"><b>WARNING:</b> Reproductive harm - p65warnings.ca.gov</span>
                    </div>
                    <div class="warning-bottom-text">This warning is required by the State of California to meet Proposition 65 requirements</div>
                </div>
            </div>
            <div [ngClass]="{'order-summary-continue-button-div': currentRouterPath != '/Shipping' &&  currentRouterPath != '/Checkout', 'order-summary-continue-button-div-center' : currentRouterPath == '/Shipping' ||  currentRouterPath == '/Checkout'  || currentRouterPath == '/EnrollmentCheckout' }"
                *ngIf="currentRouterPath != '/Confirmation'">
                <a *ngIf="currentRouterPath != '/Subscribe' && currentRouterPath != '/Shipping' &&  currentRouterPath != '/Checkout' && currentRouterPath !== '/EnrollmentCheckout'"
                    data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                    class="button continue-button w-button hover-white"
                    (click)="onClickChange();_sharedMethodService.closeCart()">Checkout</a>
                <a *ngIf="currentRouterPath === '/Subscribe'"
                    data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                    class="button continue-button w-button hover-white"
                    (click)="subscribeDialog();_sharedMethodService.closeCart()">Checkout</a>    
                <a *ngIf="currentRouterPath == '/Shipping'" data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                    class="button continue-button continue-button-mobile w-button" (click)="onClickChange();">Continue
                    To
                    Payment</a>
                <a *ngIf="currentRouterPath == '/Checkout' || currentRouterPath == '/EnrollmentCheckout'"
                    data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                    class="button continue-button continue-button-mobile w-button" [class.disabled]="_sharedMethodService.submissionInProgress ? true: false"
                    (click)="onClickChange(true); ">Review your Order</a>
            </div>
        </div>
    </ng-container>
    <mat-sidenav *ngIf="!_enrollSession.mobileViewMethod() && _sharedMethodService.isOpen">
        <ng-container *ngIf="!_enrollSession.mobileViewMethod()">
            <div class="order-summary-div">
                <div class="cartClose"><span class="remove-item c-green" (click)="_sharedMethodService.closeCart(sideNav)">Close</span>
                </div>
                <div class="summaryCart">
                    <ng-container
                        *ngIf="currentRouterPath == '/OrderReview' && (!_enrollSession.data.RetailOrderSummary || (!_enrollSession.data.RetailOrderSummary.Enrollpack || _enrollSession.data.RetailOrderSummary.Enrollpack.length == 0) && (!_enrollSession.data.RetailOrderSummary.InitialOrders || _enrollSession.data.RetailOrderSummary.InitialOrders.length == 0) && (!_enrollSession.data.RetailOrderSummary.SmartOrders || _enrollSession.data.RetailOrderSummary.SmartOrders.length == 0))">
                        <div class="order-summary-totals-div-block">
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text">SubTotal</div>
                                <div class="order-summary-text">Shipping</div>
                                <div class="order-summary-text">Tax</div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                    QV
                                </div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                    BV
                                </div>
                            </div>
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text">---</div>
                                <div class="order-summary-text">---</div>
                                <div class="order-summary-text">---</div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">---
                                </div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">---
                                </div>
                            </div>
                        </div>
                        <div class="order-summary-divider"></div>
                        <div class="order-summary-totals-div-block">
                            <div class="order-summary-totals-text-div-block order-summary-labels">
                                <div class="order-summary-text order-summary-text-total">Your Total:</div>
                            </div>
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text order-summary-text-total">---</div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="order-summary-product-div-block"
                        *ngIf="_enrollSession.data.RetailOrderSummary && ((_enrollSession.data.RetailOrderSummary.Enrollpack && _enrollSession.data.RetailOrderSummary.Enrollpack.length > 0) || (_enrollSession.data.RetailOrderSummary.InitialOrders && _enrollSession.data.RetailOrderSummary.InitialOrders.length > 0) || (_enrollSession.data.RetailOrderSummary.SmartOrders && _enrollSession.data.RetailOrderSummary.SmartOrders.length > 0))">
                        <div class="w-100-div">
                            <div class="w-100">
                                <div class=" order-summary-h1">Today's Order</div>
                            </div>
                        </div>
                        <div
                            *ngFor="let item of _enrollSession.data.RetailOrderSummary.Enrollpack | sortBy: 'asc':'-Price'; let i = index">
                            <div class="w-layout-grid order-summary-grid-smart">
                                <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                    id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text cursor-pointer"></div>
                                <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId" [src]="item.SmallImage"
                                    onError="this.src='assets/img/noimage.png';" loading="lazy"
                                    id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                    class="order-summary-img img-box">
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text">{{item.Description}}</div>
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text" fxLayoutAlign="center start"
                                    *ngIf="currentRouterPath =='/Confirmation'">{{item.Quantity}}</div>
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                    fxLayoutAlign="start center" *ngIf="currentRouterPath !='/Confirmation'">
                                    <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                        <div class="input-remove" fxLayoutAlign="center center">
                                            <span>{{item.Quantity}}</span>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        class="order-summary-product-price"
                                        *ngIf="(_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10)">
                                        ${{item?.Price |number : '1.2-2'}}
                                        USD</div>
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                         class="order-summary-product-price"
                                         *ngIf="(_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10)">
                                        ${{getItemPrice(item)
                                        |number : '1.2-2'
                                        }} USD
                                    </div>
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        *ngIf="_sharedMethodService.validCustomerType() && (item.ParentId == item.ItemCode || !item.ParentId) && item.ItemCode!='11050-FREE' && item.ItemCode!='11050' && item.ItemCode!='11020'"
                                        class="order-summary-product-price">{{item.BV || 0}} BV</div>
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        *ngIf="_sharedMethodService.validCustomerType() && (item.ParentId == item.ItemCode || !item.ParentId) &&  ( item.ItemCode=='11050-FREE' || item.ItemCode=='11050' || item.ItemCode=='11020')"
                                        class="order-summary-product-price">{{item.BV || 0}} QV</div>
                                </ng-container>
                            </div>

                            <!-- Colorado Retail Delivery Fees -->
                            <div class="w-layout-grid order-summary-grid"
                                *ngIf="_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.price"
                                style="margin-bottom: 10px;">
                                <div fxLayoutAlign="center center">
                                    <img src="../../../assets/images/image/retail-delivery-fees.png" loading="lazy"
                                        id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                        class="order-summary-img img-box delivery-fee-image">
                                </div>
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text">{{
                                    _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees.description
                                    }}</div>
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text" fxLayoutAlign="center start"
                                    *ngIf="currentRouterPath =='/Confirmation'"> {{
                                    _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees.quantity
                                    }} </div>
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                    fxLayoutAlign="start center" *ngIf="currentRouterPath !='/Confirmation'">
                                    <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                    </div>
                                </div>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price">${{
                                    _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees.price
                                    |number : '1.2-2'}} USD</div>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price cursor-pointer"
                                    *ngIf="currentRouterPath !='/Confirmation' && i == 0">
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let item of _enrollSession.data.RetailOrderSummary.InitialOrders; let i = index">
                            <div class="w-layout-grid order-summary-grid-enhance">
                                <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                    id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text cursor-pointer"></div>
                                <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId" [src]="item.SmallImage"
                                    onError="this.src='assets/img/noimage.png';" loading="lazy"
                                    id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                    class="order-summary-img img-box">
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text">{{item.Description}}</div>
                                <div class="quantityMeasureBox" id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                    fxLayoutAlign="start center"
                                    *ngIf="(item.ParentId == item.ItemCode || !item.ParentId)">
                                    <div class="input-group" fxLayout="row" fxLayoutAlign="baseline center">
                                        <div class="input-control" fxLayoutAlign="center center">
                                            <span fxLayoutAlign="center center" class="remove-item" (click)="removedOrder(i , item.Quantity, item.ItemCode, 'todayOrder')">
                                                <img src="../../../assets/icons/bin.png" alt="" class="cart-w-20"></span>
                                        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div class="input-control" fxLayoutAlign="center center">
                                            <span>{{item.Quantity}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="item.ParentId != item.ItemCode && item.ParentId" id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column" fxLayoutAlign="start center">
                                    <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                        <div class="input-remove" fxLayoutAlign="center center">
                                            <span>{{item.Quantity}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price"
                                    *ngIf="(item.ParentId == item.ItemCode || !item.ParentId) && ((_enrollSession.data.priceType == 10  && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data.priceType==10))">${{item.Price |number :
                                    '1.2-2'}} USD</div>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price"
                                    *ngIf="(_enrollSession.data?.priceType != 10 && !_enrollSession.data.IsLoggedIn) || (_enrollSession.data.IsLoggedIn && _enrollSession.data?.priceType!=10)">
                                    ${{getItemPrice(item) |number : '1.2-2'}}
                                    USD
                                </div>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    *ngIf="_sharedMethodService.validCustomerType() && (item.ParentId == item.ItemCode || !item.ParentId) && item.ItemCode!='11050-FREE' && item.ItemCode!='11050' && item.ItemCode!='11020'"
                                    class="order-summary-product-price">{{item.BV || 0}} BV</div>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    *ngIf="_sharedMethodService.validCustomerType() && (item.ParentId == item.ItemCode || !item.ParentId) &&  ( item.ItemCode=='11050-FREE' || item.ItemCode=='11050' || item.ItemCode=='11020')"
                                    class="order-summary-product-price">{{item.BV || 0}} QV</div>
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-divider"></div>
                    <div
                        *ngIf="_enrollSession.data.RetailOrderSummary.Enrollpack && _enrollSession.data.RetailOrderSummary.Enrollpack.length > 0 || _enrollSession.data.RetailOrderSummary.InitialOrders && _enrollSession.data.RetailOrderSummary.InitialOrders.length > 0  || _enrollSession.data.RetailOrderSummary.AddOnOrders && _enrollSession.data.RetailOrderSummary.AddOnOrders.length > 0">
                        <div class="order-summary-totals-div-block"
                            *ngIf="_calculateAutoOrder.getIsCallCalculateOrder()">
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text">SubTotal</div>
                                <div class="order-summary-text">Shipping</div>
                                <div class="order-summary-text">Tax</div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                    QV
                                </div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                    BV
                                </div>
                                <div class="order-summary-text">Order Total</div>
                            </div>
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.SubTotal
                                        |number :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ShippingTotal
                                        |number : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.Tax|number :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                    <span>{{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.BV}}</span>
                                </div>
                                <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                    <span>{{_enrollSession.data?.RetailOrderSummary.InitialOrderDetails?.CV}} </span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.Total|number :
                                        '1.2-2'}} USD</span>
                                </div>
                            </div>
                        </div>
                        <div class="order-summary-divider"></div>
                        <div class="order-summary-totals-div-block"
                            *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder()">
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text">SubTotal</div>
                                <div class="order-summary-text">Shipping</div>
                                <div class="order-summary-text">Tax</div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                    QV
                                </div>
                                <div class="order-summary-text" *ngIf="_sharedMethodService.validCustomerType()">Total
                                    BV
                                </div>
                                <div class="order-summary-text">Your Total</div>
                                <div class="order-summary-text" *ngIf="_calculateRetailOrderService.isSelected">Reward Points Redeemed</div>
                                <div class="order-summary-text" *ngIf="_calculateRetailOrderService.isSelected">Credit Card Payment Due</div>
                                <div class="order-summary-text italic-text" id="totalsaving">Loyalty Discount Savings</div>
                            </div>
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.SubTotal
                                        |number :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ShippingTotal
                                        |number : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.Tax|number :
                                        '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                    <span>{{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.BV}}</span>
                                </div>
                                <div class="order-summary-text t-a-r " *ngIf="_sharedMethodService.validCustomerType()">
                                    <span>{{_enrollSession.data?.RetailOrderSummary.InitialOrderDetails?.CV}} </span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails?.Total|number :
                                        '1.2-2'}} USD</span>
                                </div>
                                <div class="order-summary-text t-a-r " *ngIf="_calculateRetailOrderService.isSelected">
                                    <span>-(${{_calculateRetailOrderService.DiscountValue |number : '1.2-2'}})</span>
                                </div>
                                <div class="order-summary-text t-a-r " *ngIf="_calculateRetailOrderService.isSelected">
                                    <span>${{_calculateRetailOrderService.totalRemainingBalance |number : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r" >
                                    <span id="totalsaving"> <b> ${{ getDiscountTotal() | number : '1.2-2'}} USD</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Smart Order Subscription- Months 2-3-->

                    <div
                        *ngIf="_enrollSession.data.RetailOrderSummary.SmartOrders && _enrollSession.data.RetailOrderSummary.SmartOrders.length>0">
                        <div class="order-summary-divider"></div>
                        <div class="order-summary-product-div-block">
                            <div class="w-100-div">
                                <div class="w-100">
                                    <div class=" order-summary-h1 fs-20">Smart Order Subscription - Months 2-3</div>
                                </div>
                            </div>
                            <div *ngFor="let item of _enrollSession.data.RetailOrderSummary.SmartOrders; let i = index">
                                <div class="w-layout-grid  order-summary-grid-smart" *ngIf="item.type=='2Month'">
                                    <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                        id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                        class="order-summary-product-text cursor-pointer"></div>
                                    <img [src]="item.SmallImage"
                                        *ngIf="(item.ParentId == item.ItemCode || !item.ParentId)"
                                        onError="this.src='assets/img/noimage.png';" loading="lazy"
                                        id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                        class="order-summary-img img-box">
                                    <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                        class="order-summary-product-text">{{item.Description}}</div>
                                    <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                        class="order-summary-product-text" fxLayoutAlign="center start"
                                        *ngIf="currentRouterPath =='/Confirmation'">{{item.Quantity}}</div>
                                    <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                        fxLayoutAlign="start center" *ngIf="currentRouterPath !='/Confirmation'">
                                        <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">

                                            <div class="input-remove" fxLayoutAlign="center center">
                                                <span>{{item.Quantity}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        *ngIf="(item.ParentId == item.ItemCode || !item.ParentId)"
                                        class="order-summary-product-price">${{item.Price |number : '1.2-2'}} USD</div>

                                </div>
                            </div>

                            <!-- Colorado Retail Delivery Fees -->
                            <div class="w-layout-grid order-summary-grid"
                                *ngIf="_enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 && _enrollSession.data.RetailOrderSummary?.shippingFormData?.State == 'CO'"
                                style="margin-bottom: 10px;">
                                <div fxLayoutAlign="center center">
                                    <img src="../../../assets/images/image/retail-delivery-fees.png" loading="lazy"
                                        id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                        class="order-summary-img img-box delivery-fee-image">
                                </div>
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text">{{
                                    _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.description
                                    }}</div>

                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text" fxLayoutAlign="center start"> {{
                                    _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.quantity
                                    }} </div>

                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price">${{
                                    _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.price
                                    |number : '1.2-2'}} USD</div>

                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price cursor-pointer">
                                </div>
                            </div>
                        </div>

                        <div class="order-summary-divider"></div>
                        <div class="order-summary-totals-div-block"
                            *ngIf="_calculateAutoOrder.getIsCallCalculateOrder() && false">
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text">SubTotal</div>
                                <div class="order-summary-text">Shipping</div>
                                <div class="order-summary-text">Tax</div>
                                <div class="order-summary-text">Smart Order Total</div>
                            </div>
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.SubTotal
                                        |number : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.ShippingTotal
                                        |number : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.Tax|number
                                        : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.Total|number
                                        : '1.2-2'}} USD</span>
                                </div>
                            </div>
                        </div>
                        <div class="order-summary-totals-div-block"
                            *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder() && false">
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text">SubTotal</div>
                                <div class="order-summary-text">Shipping</div>
                                <div class="order-summary-text">Tax</div>
                                <div class="order-summary-text">Smart Order Total</div>
                            </div>
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text t-a-r">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.SubTotal
                                        |number : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.ShippingTotal
                                        |number : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.Tax|number
                                        : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['2Month']?.Total|number
                                        : '1.2-2'}} USD</span>
                                </div>
                            </div>
                        </div>

                        <!--Smart Order Subscription- Months 4+-->
                        <div class="order-summary-divider"></div>
                        <div class="order-summary-product-div-block">
                            <div class="w-100-div">
                                <div class="w-100">
                                    <div class=" order-summary-h1 fs-20">Smart Order Subscription - Month 4+</div>
                                </div>
                            </div>
                            <div *ngFor="let item of _enrollSession.data.RetailOrderSummary.SmartOrders; let i = index">
                                <div class="w-layout-grid  order-summary-grid-smart" *ngIf="item.type=='4Month'">
                                    <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                        id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                        class="order-summary-product-text cursor-pointer"></div>
                                    <img [src]="item.SmallImage"
                                        *ngIf="(item.ParentId == item.ItemCode || !item.ParentId)"
                                        onError="this.src='assets/img/noimage.png';" loading="lazy"
                                        id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                        class="order-summary-img img-box">
                                    <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                        class="order-summary-product-text">{{item.Description}}</div>
                                    <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                        class="order-summary-product-text" fxLayoutAlign="center start"
                                        *ngIf="currentRouterPath =='/Confirmation'">{{item.Quantity}}</div>
                                    <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                        fxLayoutAlign="start center" *ngIf="currentRouterPath !='/Confirmation'">
                                        <div class="input-group-lifestyle" fxLayout="row" fxLayoutAlign="center center">
                                            <div class="input-remove" fxLayoutAlign="center center">
                                                <span>{{item.Quantity}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        class="order-summary-product-price"
                                        *ngIf="(item.ParentId == item.ItemCode || !item.ParentId)">${{item.Price |number
                                        :
                                        '1.2-2'}} USD</div>
                                </div>
                            </div>

                            <!-- Colorado Retail Delivery Fees -->
                            <div class="w-layout-grid order-summary-grid"
                                *ngIf="_enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 && _enrollSession.data.RetailOrderSummary?.shippingFormData?.State == 'CO'"
                                style="margin-bottom: 10px;">
                                <div fxLayoutAlign="center center">
                                    <img src="../../../assets/images/image/retail-delivery-fees.png" loading="lazy"
                                        id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                        class="order-summary-img img-box delivery-fee-image">
                                </div>
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text">{{
                                    _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.description
                                    }}</div>

                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text" fxLayoutAlign="center start"> {{
                                    _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.quantity
                                    }} </div>

                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price">${{
                                    _enrollSession.data.RetailOrderSummary.InitialOrderDetails?.ColoradoRetailDeliveryFees?.price
                                    |number : '1.2-2'}} USD</div>

                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price cursor-pointer">
                                </div>
                            </div>
                        </div>
                        <div class="order-summary-divider"></div>
                        <div class="order-summary-totals-div-block"
                            *ngIf="_calculateAutoOrder.getIsCallCalculateOrder() && false">
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text">SubTotal</div>
                                <div class="order-summary-text">Shipping</div>
                                <div class="order-summary-text">Tax</div>
                                <div class="order-summary-text">Smart Order Total</div>
                            </div>
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.SubTotal
                                        |number : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.ShippingTotal
                                        |number : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.Tax|number
                                        : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.Total|number
                                        : '1.2-2'}} USD</span>
                                </div>
                            </div>
                        </div>
                        <div class="order-summary-totals-div-block"
                            *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder() && false">
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text">SubTotal</div>
                                <div class="order-summary-text">Shipping</div>
                                <div class="order-summary-text">Tax</div>
                                <div class="order-summary-text">Smart Order Total</div>
                            </div>
                            <div class="order-summary-totals-text-div-block">
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.SubTotal
                                        |number : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.ShippingTotal
                                        |number : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.Tax|number
                                        : '1.2-2'}}</span>
                                </div>
                                <div class="order-summary-text t-a-r ">
                                    <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails['4Month']?.Total|number
                                        : '1.2-2'}} USD</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="padding-warning-box" *ngIf="checkwarningStatement()">
                        <div class="warning-text-for-CA">
                            <img src="../../../assets/icons/warning 1.png" width="15">
                            <span class="f-size-warning"><b>WARNING:</b> Reproductive harm - p65warnings.ca.gov</span>
                        </div>
                        <div class="warning-bottom-text">This warning is required by the State of California to meet Proposition 65 requirements</div>
                    </div>
                </div>
                <div [ngClass]="{'order-summary-continue-button-div': currentRouterPath != '/Shipping' &&  currentRouterPath != '/Checkout', 'order-summary-continue-button-div-center' : currentRouterPath == '/Shipping' ||  currentRouterPath == '/Checkout' || currentRouterPath == '/EnrollmentCheckout'}"
                    *ngIf="currentRouterPath != '/Confirmation'" style="margin-bottom: 2%;">
                    <a *ngIf="currentRouterPath != '/Subscribe' && currentRouterPath != '/Shipping' &&  currentRouterPath != '/Checkout' && currentRouterPath !== '/EnrollmentCheckout'"
                        data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                        class="button continue-button w-button hover-white"
                        (click)="onClickChange();_sharedMethodService.closeCart()">Checkout</a>
                    <a *ngIf="currentRouterPath === '/Subscribe'"
                        data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                        class="button continue-button w-button hover-white"
                        (click)="subscribeDialog();_sharedMethodService.closeCart()">Checkout</a>
                    <a *ngIf="currentRouterPath == '/Shipping'" data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                        class="button continue-button continue-button-shipping w-button hover-white"
                        (click)="onClickChange();_sharedMethodService.closeCart()">Continue To Payment</a>
                    <a *ngIf="currentRouterPath == '/Checkout' || currentRouterPath == '/EnrollmentCheckout'"
                        data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                        class="button continue-button continue-button-shipping w-button hover-white"  [class.disabled]="_sharedMethodService.submissionInProgress ? true: false"
                        (click)="onClickChange(true); _sharedMethodService.closeCart()">Review your Order</a>
                </div>
            </div>
        </ng-container>
    </mat-sidenav>
</section>